<div class="mat-card card">

  <div class="row">
    <div class="col-8">
      <h2 class="main-heading">
        Manage Clinics

      </h2>
    </div>
    <div class="col-4">
      <button mat-button class="submit" (click)="addclient()">Add Client</button>

    </div>
  </div>
  <div>
    <input matInput (keyup)="applyFilterGrid($event.target.value)" placeholder="Filter">
  </div>
  <div class="matTableDiv">
    <table mat-table [dataSource]="reserveData" class="mat-elevation-z8" matSort>


      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element" id="Name">{{element?.name || ''}}
          <!-- <ng-container *ngIf="element.name == undefined || element.name == '';else Name">NA</ng-container>
                    <ng-template #Name>{{element.name}}</ng-template> -->
          <!-- <ng-container>{{element?.name || ''}}</ng-container> -->
        </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
        <td mat-cell *matCellDef="let element" id="Mobile">{{element.managerId?.mobile || ''}}
          <!-- <ng-container
            *ngIf="element.managerId.mobile == undefined || element.managerId.mobile == '';else Name">NA</ng-container>
          <ng-template #Name>{{element.name}}</ng-template>
          <ng-container>{{element?.name || ''}}</ng-container> -->
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Joining Date </th>
        <td mat-cell *matCellDef="let element" id="createdAt">
          {{ element?.createdAt ? (element?.createdAt | date:'dd-MM-yy') : '' }}
          <!-- <ng-container *ngIf="element.createdAt == undefined || element.createdAt== '';else Name">NA</ng-container>
                    <ng-template #Name>{{ element.createdAt | date:'dd-MM-yy' }}</ng-template> -->
        </td>
      </ng-container>
      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
        <td mat-cell *matCellDef="let element" id="isActive">
          {{element?.isActive ? 'Activated' : 'Deactivated'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="staff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Staff </th>
        <td mat-cell *matCellDef="let element" id="staff">
          <span (click)="showStaffList(element?.staff)">{{element?.staff?.length || 0}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="patient">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Patient </th>
        <td mat-cell *matCellDef="let element" id="patient">
          <span (click)="showPatientList(element?.patient)">{{element?.patient?.length || 0}}</span>
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="manname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager Name </th>
            <td mat-cell *matCellDef="let element" id="name">
              <ng-container *ngIf="element.managerId.name == undefined || element.managerId.name == '';else Name">NA</ng-container>
              <ng-template #Name>{{element.managerId.name}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="manemail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manager Email </th>
            <td mat-cell *matCellDef="let element" id="email">
              <ng-container *ngIf="element.managerId.email == undefined || element.managerId.email == '';else email">NA</ng-container>
              <ng-template #email>{{element.managerId.email}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="manmobile">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager Mobile </th>
            <td mat-cell *matCellDef="let element" id="mobile">
              <ng-container *ngIf="element.managerId.mobile == undefined || element.managerId.mobile == '';else mobile">NA</ng-container>
              <ng-template #mobile>{{element.managerId.mobile}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="branch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Branch </th>
            <td mat-cell *matCellDef="let element">
              <span>{{element.branch}}</span>
            </td>
          </ng-container>
    
     -->

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span>
          <span class="material-icons editDelete" (click)="deleteclient(element._id)" title="Delete">delete</span>
          <span class="material-icons editDelete"
            (click)="sendWhatsappClient(element.managerId.mobile,element.managerId.name,element.slug,element.name)"
            title="Whatsapp">
            <img class="whatsapp" src="../../../../assets/imgs/whatsapp-2.png" />
          </span>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns">

      </tr>
      <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">

      </tr>

    </table>
    <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
    </mat-paginator>
  </div>

</div>