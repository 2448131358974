import { usersService } from '../../../services/tikaUser/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
addForm:any;
  constructor(
    private r:Router,
    private userService:usersService,
    private toast:ToastComponent,
    private crudService : CrudServiceService
  ) {
    this.addForm={
      email:""
    }
   }

  ngOnInit(): void {
    this.loggedIn()
  }
  loggedIn()
  {
    if(localStorage.getItem("loginUser") != null){
      this.loggedIn()
    }
  }
  
  login(){
    this.r.navigate(['/admin/login'])
  }

  submit(){
    console.log(this.addForm)
    let filter = {"where" : {email : this.addForm.email}}
    this.crudService.getAll(environment.api.admin , filter).then((res:any)=>{
      if(res && res.length > 0){{
        this.addForm.url = "https://dogsjoys-admin.web.app/admin/reset"
        this.userService.forgetPassword(this.addForm , environment.api.admin).then((res)=>{
          this.toast.toastSuccess("Password reset link has been sent to the email");

        })
      }
        // this.userService.forgetPassword(this.addForm).then((res)=>
        // {
        //   this.login();
        //   console.log(res)
        // })
      }else{  
        this.toast.toastError("Admin with this email does not exist")
      }
    })
  }

}
