import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { usersService } from 'src/app/services/tikaUser/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // @ViewChild('sidenav', { static: true }) 
  // drawer: MatSidenav;
  @Input() inputSideNav: MatSidenav;
  dropdownFlag:boolean = false;
  constructor(
    private drawer:MatSidenav,
    private r:Router,
    public loader:LoaderService,
    public userService:usersService
  ) {
    setTimeout(() => {
      
      console.log(this.userService.checkprofile)
    }, 1000);
   }

  ngOnInit(): void {
  }

  dashboard(){
    this.r.navigate(['/admin/dashboard']);
  }

  openToggle(){
    this.drawer.toggle();
    console.log("openToggle");
  }
  
  toggleDrawer(){
    if(this.loader.dropdown == true){
      this.loader.dropdownTimer = false;
      this.loader.dropdown = false;
    }else{
      this.loader.dropdownTimer = false;
      this.loader.dropdown = true;
    }
 
    setTimeout(() => {
      this.loader.dropdownTimer = true;
    }, 300);
  }

  toggleSearch(){
    this.loader.searchFlag = true;
  }


}
