import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { usersService } from '../tikaUser/user.service';

@Injectable({
  providedIn: 'root'
})
export class VendorServicesService {
  userId: any;

  constructor(private apiService:usersService,
    private route:Router) { 
      this.userId = JSON.parse(localStorage.getItem('userProfile'));
    }
    canActivate():any{


      if(localStorage.getItem("userProfile")){
        if(this.userId.role == 'vendor'){
          return true;
      }else{
        this.route.navigate(['/admin/dashboard']);

      }
    }
    else{
      this.route.navigate(['/doctor/login']);
      return false;
    }
  }


  }