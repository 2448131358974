
            
          <mat-card>
           <div class="row ">
               <div class="co-12 heading">
                    Register    
                </div>    
                <div class="col-12" class="field">
                    <input class="inputbox" placeholder="username">
                    <span class="material-icons">
                        check_circle_outline
                        </span>
                </div>
                <div class="col-12" class="field">
                    <input class="inputbox" type="password" placeholder="Password">
                    <span class="material-icons">
                        check_circle_outline
                        </span>
                </div>
                <div class="col-12" class="field">
                    <input class="inputbox"  type="password" placeholder="Confirm Password">
                    <span class="material-icons">
                        check_circle_outline
                        </span>
                </div>
                <div class="col-12">
                    <mat-checkbox>I agree to the terms
                    </mat-checkbox>
                </div>    
                <div class="col-12">
                <button mat-stroked-button class="button1" (click)="register()">Submit</button>
                </div>
                <div class="col-12">
                    <p class="accunt">Already have and account ?<span (click)="login()"> Login</span></p>
                </div>    
            </div>
          </mat-card>
      
    