<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading" *ngIf = "!id">Add Delivery Guy</h2>  
                      <h2 class="heading" *ngIf = "id">Edit Delivery Guy</h2>  

                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="name" [(ngModel)]="addForm.name"  value="" #name="ngModel" placeholder="FullName" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
                      <div
  [hidden]="name.valid || name.pristine"
  class="erro1"
>Name is Required
</div>
    
                    </div>

                  <div class="col-12">
                    <input class="inputbox" placeholder="Email" name="email" [(ngModel)]="addForm.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email [disabled]="true" >
                    <span class="material-icons" >
                        email
                        </span>
                    <div
                    [hidden]="email.valid || email.pristine"
                    class="erro1"
                  >
                    <div *ngIf="email.errors?.required">Email is required</div>
                    <div *ngIf="email.errors?.email">
                      Email must be a valid email address
                    </div></div>
            </div> 


            <div class="col-12">
              <input class="inputbox" placeholder="Mobile Number" name="mobile"  type = "number" [(ngModel)]="addForm.mobile" #mobile="ngModel"   [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" minlength="10" maxlength="10" required  >
              <span class="material-icons" >
                  phone
                  </span>
                  <div
                  [hidden]="name.valid || name.pristine"
                  class="erro1"
                >Mobile is Required
                </div>
      </div> 



                  <!-- <div class="col-12" *ngIf = "!id">
                      <input class="inputbox"  placeholder="password" name="password"  type="{{type}}" [(ngModel)]="addForm.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" minlength="4" required >
                      <span class="material-icons" (click)="changeVisibility()">
                        {{Visibility}}
                        </span>
               <div
  [hidden]="password.valid || password.pristine"
  class="erro1"
>
  Password is required(Minimum 4 Character)
</div>
                    </div> -->





                  <div class="col-12">

              
          
                  <div class="col-12">
                </div>
           
                      <button mat-button [disabled]="f.invalid" class="submit" (click)="AddCustomer()">submit</button>
                      <mat-slide-toggle class="toogle"
                      [(ngModel)]="addForm.status"
                      name="status"
                      #status="ngModel"
                      >status</mat-slide-toggle
                    >
                  </div>    
                  
              </div> 
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 