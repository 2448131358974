import { usersService } from '../../../services/tikaUser/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettings } from './../../../core/constant';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  addForm:any;
  date=new Date()
  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";id:any;
    constructor(private ApiService:usersService,private activeRoute:ActivatedRoute,
      private router:Router) { 
      this.addForm=
      {
        fullName:'',
        emailVerified: true,
        realm: AppSettings.ROLE.USER,
        status: true,
        email:'',
        updatedAt:this.date
      }
      this.id=this.activeRoute.snapshot.paramMap.get("userId");
      console.log(this.id)
    }
  
    ngOnInit(): void {
      this.getSubAdmin()
    }
  
  getSubAdmin()
  {
    this.ApiService.getUserDetailForSubAdmins(this.id).then((res)=>
    {
      this.addForm=res;
      console.log(this.addForm)
    })
  }
  changeVisibility()
     {
        if(this.Visibility=="visibility_off")
        {
          this.Visibility="visibility"
          this.type='text'
        }
        else if(this.Visibility=="visibility")
        {
          this.Visibility='visibility_off'
          this.type='password';
        }
     }
    AddSubAdmin()
    {
      this.ApiService.editUser(this.addForm,this.id).then((res)=>
      {
        this.router.navigate(['/admin/subadmin'])
      })
    }
  }
  
