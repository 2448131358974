import { Component, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
export interface DialogData {
  customer: any;
}
@Component({
  selector: 'app-vet-customers',
  templateUrl: './vet-customers.component.html',
  styleUrls: ['./vet-customers.component.scss']
})
export class VetCustomersComponent implements OnInit {
  displayedColumns: string[] = ['name','email','mobile','status','createdAt'];
  dataSource :any = new MatTableDataSource;
  @Output() options:any = "data";
  maxperpage : number = 100;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  reserveData:any;
  reserveDataForFilter : any;
  constructor(public dialogRef: MatDialogRef<VetCustomersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      console.log(this.data)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort=this.sort;
      this.getAllSubadmin()
     }

     getAllSubadmin()
     {
   
         this.reserveData = this.data.customer;
         this.reserveData.map((element)=>{
           element.check = false;
         })
         this.reserveDataForFilter = this.reserveData;
         this.dataSource.data=this.reserveData;
     }

  ngOnInit(): void {
  }

}
