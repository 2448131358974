




<div  class="mat-card card">




  <div class="row">
    <div class="col-8">
        <h2 class="main-heading">
          Manage Location
     
        </h2> 
    </div>  
    <div class="col-4">
      <button mat-button class="submit" (click)="addUser()">Add Location</button>
    </div>
  </div> 
  <div class="matTableDiv">
<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">


    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
      <td mat-cell *matCellDef="let element" id="city">
        <ng-container *ngIf="element.city == undefined || element.city == '';else city">NA</ng-container>
                    <ng-template #city>{{element.city}}</ng-template>
 </td>
    </ng-container>







    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> status </th>
      <td  mat-cell *matCellDef="let element"  id="status" 
      >
        <span [ngClass]="element.status ? 'StatusGreen' :'StatusRed' ">
          {{ element.status ? 'Active' : 'Inactivate' }}
        </span>
      </td> 
    </ng-container>

  <ng-container  matColumnDef="action">
     <th mat-header-cell *matHeaderCellDef > Action </th>
    <td mat-cell *matCellDef="let element">
        <span class="material-icons editDelete" (click)="deleteUser(element._id)" title="Delete">delete</span>
</td>
  </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns">

    </tr>
    <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">

    </tr>

  </table>
  <mat-paginator [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageEvent = $event">
</mat-paginator>
 </div>
</div>
 