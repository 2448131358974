import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  reserveData: any;
  reserve: any;
  Data: any;
  clientCount: any;

  constructor(private crudService: CrudServiceService) {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.getAllSubadmin();
  }
  getAllSubadmin() {
    this.crudService
      .count(environment.api.businessUser, {})
      .then((res: any) => {
        console.log(res);
        this.reserveData = res;
        console.log(this.reserveData);
      });
    this.crudService
      .count(environment.api.appoinment, {})
      .then((result: any) => {
        console.log(result);
        this.reserve = result;
        console.log(this.reserve);
      });
    this.crudService
      .count(environment.api.customer, {})
      .then((response: any) => {
        console.log(response);
        this.Data = response;
        console.log(this.Data);
      });
    this.crudService
      .count(environment.api.clients + '/get', {})
      .then((res: any) => {
        console.log(res);
        this.clientCount = res;
        //  console.log(this.reserveData)
      });
  }
}
