import { Router } from '@angular/router';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { usersService } from 'src/app/services/tikaUser/user.service';
@Component({
  selector: 'app-rx-listing',
  templateUrl: './rx-listing.component.html',
  styleUrls: ['./rx-listing.component.scss']
})
export class RxListingComponent implements OnInit{
displayedColumns: string[] = [ 'products' ,'status', 'createdAt' ,'action'];
dataSource :any = new MatTableDataSource;
@Output() options:any = "data";
maxperpage : number = 100;
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort, {static: true}) sort: MatSort;
length = 100;
pageSize = 10;
pageSizeOptions: number[] = [5, 10, 25, 100];
pageEvent: PageEvent;
reserveData:any;
constructor(
private apiService:usersService,
private route:Router,
private crudService : CrudServiceService,
private dialog: DialogComponent
) {

}

//search Start


searchVariable:any;
searchFlag:boolean = false;
searchHelperFlag:boolean = false;
refreshFlag:boolean = false;
showSearch(){
  if(this.searchHelperFlag == true){
    this.hideSearch()
  }else{
    this.searchHelperFlag = true;
    setTimeout(() => {
      this.searchFlag = true;
    }, 200);
  }
}
hideSearch(){
  this.searchFlag = false;
  setTimeout(() => {
    this.searchHelperFlag = false;
  }, 200);
}

 searchComplete(){
   let userId = JSON.parse(localStorage.getItem('userProfile')).id;
   console.log(this.searchVariable);
   let filter = {
    "where":{
      "and":[
        {
          "realm":"user"
        },
        {
          "or":[
            {"fullName":{like:this.searchVariable,"options":"i"}},
            {"email":{like:this.searchVariable,"options":"i"}},
            {"mobileNo":{like:this.searchVariable,"options":"i"}},
          ]
        }
      ],
     
    }
   }
    this.apiService.getAllUsersBySearch(filter).then((res:any)=>{
      console.log(res);
      this.dataSource.data = res;
      this.reserveDataForFilter = res;

      if(this.searchVariable !== undefined && this.searchVariable !== ''){
        this.refreshFlag = true;
      }else{
        this.refreshFlag = false;
      }
      setTimeout(() => {
        if(!res.length){
          this.apiService.snackbarCmp.toastError("Data is not found related to search : "+this.searchVariable)
        }
      }, 100);
    })
   this.hideSearch();

 }

 refreshData(){
   this.dataSource.data = this.reserveData;
   this.reserveDataForFilter = this.reserveData;
   this.searchVariable = undefined;
   this.refreshFlag = false;
 }



//search End




//filter
filter:any = {
"status":"none",
"role":"none",
"startDate":"",
"endDate":""
}
filterFlag:boolean = false;
filterFlagHelper:boolean =false;
reserveDataForFilter:any;

showFilter(){
if(this.filterFlagHelper == true){
  this.hideFilter()
}else{
  this.filterFlagHelper = true;
  setTimeout(() => {
    this.filterFlag = true;
  }, 200);
}
}
hideFilter(){
this.filterFlag = false;
setTimeout(() => {
  this.filterFlagHelper = false;
}, 200);
}

resetData(){
this.filter = {
  "status":"none",
  "role":"none",
  "startDate":"",
  "endDate":""
}
this.dataSource.data = this.reserveDataForFilter;
this.hideFilter();

}

filterData(){
console.log(this.filter);
this.setupFilter();
this.applyFilter('');
}

setupFilter() {
 
this.dataSource.filterPredicate = (d:any,filter: string) => {  
let status = d['status'].toString() || '';
let role = d['role'] && d['role'].toLowerCase() || '';
let date = d['createdAt'] || '';

let statusText = this.filter.status.trim().toLowerCase();
let roleText = this.filter.role.trim().toLowerCase();
let dateText = this.filter.startDate;

console.log(status == statusText, status , statusText);

  this.hideFilter();
 
  return  (
    (statusText == 'none' || status.toString() == statusText )
    && 
    (roleText == 'none' || role.indexOf(roleText) !== -1)
    && 
    (dateText == '' ||  (new Date(this.filter.startDate).getTime() < new Date(date).getTime() && new Date(this.filter.endDate).getTime() > new Date(date).getTime() ) )
  );
};
setTimeout(() => {
  if(this.dataSource._renderData._value.length == 0){
    this.apiService.snackbarCmp.toastError("No Data found according to the filter criteria.")
  }
}, 100);
}

applyFilter(e:string)
{
this.dataSource.filter = "value_changed";
}

// filter End


 ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort=this.sort;
  this.getAllSubadmin()
}

 getPageSizeOptions(): number[]{
  if(this.paginator.length>this.maxperpage)
  return [5, 10,50,this.maxperpage, this.dataSource.data.length];
  else
  return [5,10,50, this.maxperpage];
}
getAllSubadmin()
{
  this.crudService.getAll(environment.api.vetPrescribe , {}).then((res:any)=>
  {
    console.log(res)
    this.reserveData = res;
    this.reserveDataForFilter = res;
    this.dataSource.data=res;
  })
}
addUser()
{
  this.route.navigate(['admin/orders/add'])
}
edit(id)
{
  this.route.navigate(['admin/RxManager/view',id])
}
deleteUser(id)
{
  this.dialog.openDialog("Delete Order","Are you sure to delete the Order?").then((isOkay)=>{
    if(isOkay){
  this.crudService.delete(id , environment.api.order).then((res)=>{
    this.getAllSubadmin();

  })
}
})
}
ngOnInit(): void {
    }
}

