import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { FileUploadService } from 'src/app/services/fileUpload/file-upload.service';
import { usersService } from 'src/app/services/tikaUser/user.service';
import { FileUpload } from 'src/app/shared/classes/file-upload';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { of } from 'rxjs';
@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.scss']
})
export class AddInventoryComponent implements OnInit {
  addForm:any;
  status : boolean = true;
  selectedFiles: FileList;
  currentUpload: FileUpload;
  icon1="check_circle";
  Visibility:any='visibility_off'
  userId: any;
  id:any;
  brands: any;
  constructor(private ApiService:usersService,
    private router:Router,
    public FileUploadService: FileUploadService,
    private userService : usersService ,
    private upSvc : FileUploadService,
    private crudService : CrudServiceService,
    private snackbar : ToastComponent,
    private activatedRoute : ActivatedRoute,) {
      this.id = this.activatedRoute.snapshot.paramMap.get("id");
      this.userId = JSON.parse(localStorage.getItem('userProfile'));
      console.log('2345----------------23333',this.userId)
      this.addForm=
      {
        brand:"",
        capacity:"",
        name : "",
        subTitle:"",
      details:'',
      ProductId:"",
      price: '',
      vendorId : this.userId._id,
      images:[],
       

    }
   
    if(this.id){
      this.crudService.getDedtail(this.id , environment.api.food).then((res:any)=>{
      // if(res && res._id){
        this.addForm = res;
      console.log('300000000000000000SOURABH VIJAYVARGIYA',this.addForm)
      // }
      })
    }
    }

  ngOnInit(): void {
  }
 
  deleteImage(image , i){
    this.upSvc.deleteUpload(image,"ProfileImage").then((res)=>{
    })
  this.addForm.images.splice(i,1);
   }

  AddSubAdmin()

{
  if(this.id){
    this.crudService.edit(this.addForm , environment.api.food).then((res:any)=>{
      this.snackbar.toastSuccess(" Food Detail Updated  successfully.")
     
      this.router.navigate(['vendor/food/list'])
    
    })
  }else{
   this.addForm.brandsDetails = this.userId.brands
    this.userService.signup(this.addForm , environment.api.food).then((res:any)=>{
      this.snackbar.toastSuccess(" FOod Detail Added  successfully.")
      
      this.router.navigate(['vendor/food/list'])
    
    })
  }
  
  
    }

    detectFiles(event) {
      if(this.addForm.images){
      }
        this.selectedFiles = event.target.files;
        this.uploadMulti();
  }
  
  uploadMulti(){
    let files = this.selectedFiles;
    let filesIndex = _.range(files.length);
    _.each(filesIndex, (idx) => {
      this.currentUpload = new FileUpload(files[idx]);
      if(this.currentUpload.file.type.split("/")[0] == "image" ){
  
      this.upSvc.pushUpload(this.currentUpload, "images").then((res) => {
        this.addForm.images.push(res);
      });
    }else{
      this.snackbar.presentToastError("Upload image only")
    }
    });
  
  }

  }


