import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { usersService } from 'src/app/services/tikaUser/user.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { FileUpload } from 'src/app/shared/classes/file-upload';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { FileUploadService } from 'src/app/services/fileUpload/file-upload.service';
@Component({
  selector: 'app-add-delivery-guy',
  templateUrl: './add-delivery-guy.component.html',
  styleUrls: ['./add-delivery-guy.component.scss']
})
export class AddDeliveryGuyComponent implements OnInit {

  userId:any;
  selectedFiles: FileList;
  currentUpload: FileUpload;
  addForm : any;
  id : any;
  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";

  constructor(private crudService : CrudServiceService,
    private activatedRoute : ActivatedRoute,
    private userService : usersService , 
    private upSvc : FileUploadService,
    private snackbar : ToastComponent,
    private toast : ToastComponent,
    private router : Router) { 
      this.userId = JSON.parse(localStorage.getItem('userProfile'));
    this.addForm = {
      name : "",
      email : "",
      mobile : "",
      role : "delivery",
      vendorId : this.userId._id,
      status : true,
        images:[],
    }

    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    if(this.id){
      this.crudService.getDedtail(this.id , environment.api.businessUser).then((res:any)=>{
        this.addForm = res;
      })
    }
  }

  changeVisibility()
  {
     if(this.Visibility=="visibility_off")
     {
       this.Visibility="visibility"
       this.type='text'
     }
     else if(this.Visibility=="visibility")
     {
       this.Visibility='visibility_off'
       this.type='password';
     }
  }

  AddCustomer(){
    console.log(this.addForm)
    if(this.addForm.name && this.addForm.email){
      if(this.id){
        this.crudService.edit(this.addForm , environment.api.businessUser).then((res)=>{
          this.toast.toastSuccess("Delivery Guy details updated successfully.");
          this.router.navigate(['/vendor/deliverys/list']);
          })
      }else{
        this.add();
    
      }
    }
    // this.toast.toastSuccess("Delivery Guy added successfully.");
  }
  add(){
 
    this.checkExistance();
  }

  ngOnInit(): void {
  }

  checkExistance():any{
  // this.crudService.checkExistanceDelivery({key : this.addForm.password}).then((res:any)=>{
    // if(res && res.message && res.message == "We can use this."){
      this.crudService.getAll(environment.api.businessUser , {mobile : this.addForm.mobile}).then((res : any)=>{
        if(res.length == 0 ){
          this.crudService.add(this.addForm , environment.api.businessUser).then((res)=>{
            this.toast.toastSuccess("Delivery Guy added successfully.");
            this.router.navigate(['/vendor/deliverys/list']);
            // return true;
    
            })
        }else{
     this.toast.toastError("Mobile Number is already used")
        }
      })
   

  //   // }else{
  //   //   this.add();
  //   //  return false;

  //   // }
  // // })
  // }


}

detectFiles(event) {
  if(this.addForm.images){
  }
    this.selectedFiles = event.target.files;
    this.uploadMulti();
}

uploadMulti(){
let files = this.selectedFiles;
let filesIndex = _.range(files.length);
_.each(filesIndex, (idx) => {
  this.currentUpload = new FileUpload(files[idx]);
  if(this.currentUpload.file.type.split("/")[0] == "image" ){

  this.upSvc.pushUpload(this.currentUpload, "images").then((res) => {
    this.addForm.images.push(res);
  });
}else{
  this.snackbar.presentToastError("Upload image only")
}
});

}
deleteImage(image , i){
  this.upSvc.deleteUpload(image,"ProfileImage").then((res)=>{
  })
this.addForm.images.splice(i,1);
 }
}