<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading">Add Vaccine</h2>  
                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="name" [(ngModel)]="addForm.name"  value="" #name="ngModel" placeholder="Vaccine Name" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
                      <div
  [hidden]="name.valid || name.pristine"
  class="erro1"
>Name is Required
</div>
    
                    </div>


                    <div class="col-12" *ngFor = "let strength of addForm.strength ; let i = index">
                        <input class="inputbox" name="strength_{{i}}" [(ngModel)]="strength.name"   placeholder="Strength"  >
                        <span class="material-icons removeIcon" (click) = "removeIcon(i)">
                          remove
                          </span>

                      </div>
                      <div class = "col-md-12">
                        <button mat-button [disabled]="!addForm.strength[addForm.strength.length-1].name" class="addButton" (click)="AddStrength()">Add</button>
  
                        </div>



                      <div class="col-12">
                        <input class="inputbox" name="company" [(ngModel)]="addForm.company"  value="" #company="ngModel" placeholder="Company" [ngClass]="{ 'is-invalid': f.submitted && company.invalid }" required >
                        <span class="material-icons" >
                          face
                          </span>
                        <div
       [hidden]="company.valid || company.pristine"
       class="erro1"
     >Company is Required
  </div>
      
                      </div>


                      <div class="col-12">

           
                        <button mat-button [disabled]="f.invalid" class="submit" (click)="AddBreed()">submit</button>
                        <mat-slide-toggle class="toogle"
                        [(ngModel)]="addForm.status"
                        name="status"
                        #status="ngModel"
                        >status</mat-slide-toggle
                      >
                    </div> 



   
                  
              </div> 
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 