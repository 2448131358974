<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading">Edit Sub Admin</h2>  
                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="username" [(ngModel)]="addForm.fullName"  value="" #username="ngModel" placeholder="FullName" [ngClass]="{ 'is-invalid': f.submitted && username.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
                      <div
  [hidden]="username.valid || username.pristine"
  class="erro1"
>Name is Required
</div>
    
                    </div>
                  <!-- <div class="row">
                  <div class="col-6 pading-left-box">
                      <input class="inputbox" placeholder="Email" name="email" [(ngModel)]="addForm.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email >
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback abcd">
                <div *ngIf="email.errors.required">Email is required</div>
                <div *ngIf="email.errors.email">Email must be a valid email address</div>
            </div> 
                    </div>    
                  <div class="col-6 pading-right-box"  >
                      <input class="inputbox" placeholder="last name">
                  </div>    
                  </div>
                  <div class="col-12">
                    <input class="inputbox" placeholder="Email" name="email" [(ngModel)]="addForm.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email >
                   <div *ngIf="f.submitted && email.invalid" class="invalid-feedback abcd">
                  <div *ngIf="email.errors.required">Email is required</div>
                  <div *ngIf="email.errors.email">Email must be a valid email address</div>
              </div>
                  </div> -->
                  <div class="col-12">
                    <input class="inputbox" placeholder="Email" name="email" [(ngModel)]="addForm.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email >
                    <span class="material-icons" >
                        email
                        </span>
                    <div
                    [hidden]="email.valid || email.pristine"
                    class="erro1"
                  >
                    <div *ngIf="email.errors?.required">Email is required</div>
                    <div *ngIf="email.errors?.email">
                      Email must be a valid email address
                    </div></div>
            </div> 
                  <div class="col-12">
                      <!-- <input class="inputbox"  placeholder="password" name="password"  type="{{type}}" [(ngModel)]="addForm.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" minlength="8" required >
                      <span class="material-icons" (click)="changeVisibility()">
                        {{Visibility}}
                        </span> -->
                      <!-- <div *ngIf="f.submitted && password.invalid" class="invalid-feedback abcd">
      <div *ngIf="password.errors.required">Password is required</div>
  </div> -->
                    </div>
                  <!-- <div class="col-12">
                   <ngx-intl-tel-input
                    [cssClass]="'custom'"
                    [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                    [enableAutoCountrySelect]="false"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.India"
                    [maxLength]="15"
                    [tooltipField]="TooltipLabel.Name"
                    [phoneValidation]="true"
                    [inputId]=""
                    name="phone"
                ></ngx-intl-tel-input>
                      <input class="inputbox"  >
                  </div> -->
                  <div class="col-12">
                    <input class="inputbox area" type='tel' name="moileNO" [(ngModel)]="addForm.mobileNo"   minlength="10" maxlength='10' placeholder="Mobile No" required #moileNO="ngModel">
                    <span class="material-icons" >
                      phone
                      </span>
                    <div
                    [hidden]="moileNO.valid || moileNO.pristine"
                    class="erro1">
                   ENTER CORRECT PHONE
                  </div>
              
          
                  <div class="col-12">
                </div>
           
                      <button mat-button [disabled]="f.invalid" class="submit" (click)="AddSubAdmin()">submit</button>
                      <mat-slide-toggle class="toogle"
                      [(ngModel)]="addForm.status"
                      name="status"
                      #status="ngModel"
                      >status</mat-slide-toggle
                    >
                  </div>    
                  
              </div> 
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 