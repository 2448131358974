import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cat-add-vaccine',
  templateUrl: './cat-add-vaccine.component.html',
  styleUrls: ['./cat-add-vaccine.component.scss']
})
export class CatAddVaccineComponent implements OnInit {
  addForm : any;
  id : any;
  constructor(private crudService : CrudServiceService,
    private router : Router,
    private activatedRoute : ActivatedRoute) {
    this.addForm = {
     range : "",
     vaccineList :[{name :"" , number : 1  , type : 'Recomanded'}]
  } ;
  this.id = this.activatedRoute.snapshot.paramMap.get("id")
  if(this.id){
    this.crudService.getDedtail(this.id , environment.api.catVaccine).then((res)=>{
      this.addForm = res;
      console.log(this.addForm)
    })
  }

  }
  AddVaccine(){
    this.addForm.vaccineList.push({name : "" , number : this.addForm.vaccineList.length+1 , type : 'Recomanded'})
  }

  AddVaccineGroup(){
   if(this.addForm.range){

    if(this.id){
      this.crudService.edit(this.addForm , environment.api.catVaccine).then((res)=>{
        this.router.navigate(['/admin/cat-vaccine/list'])
        })
    }else{
      this.crudService.add(this.addForm , environment.api.catVaccine).then((res)=>{
        this.router.navigate(['/admin/cat-vaccine/list'])
        })
    }
 
   }
  }

  ngOnInit(): void {
  }
  removeIcon(i){
    this.addForm.vaccineList.splice(i,1);
  }
  

}
