<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading" *ngIf = "!id">Add Customer</h2>  
                      <h2 class="heading" *ngIf = "id">Edit Customer</h2>  

                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="name" [(ngModel)]="addForm.name"  value="" #name="ngModel" placeholder="FullName" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required >
                      <span class="material-icons" >
                        face

                        </span>
                      <div
  [hidden]="name.valid || name.pristine"
  class="erro1"
>Name is Required
</div>
    
                    </div>

                    <div class="col-12">
                      <input class="inputbox" placeholder="Mobile"  name="mobile" [(ngModel)]="addForm.mobile" #mobile="ngModel" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required  >
                      <span class="material-icons" >
                          mobile
                          </span>
                      <div
                      [hidden]="mobile.valid || mobile.pristine"
                      class="erro1" >
                      <div *ngIf="mobile.errors?.required">Email is required</div>
                      <div *ngIf="mobile.errors?.mobile">
                      Mobile must be a valid email address
                      </div></div>
                     </div> 

                  <div class="col-12">
                    <input class="inputbox" placeholder="Email" readonly name="email" [(ngModel)]="addForm.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"  email >
                    <span class="material-icons" >
                        email
                        </span>
                    <div
                    [hidden]="email.valid || email.pristine"
                    class="erro1" >
                    <div *ngIf="email.errors?.required">Email is required</div>
                    <div *ngIf="email.errors?.email">
                      Email must be a valid email address
                    </div></div>
                   </div> 
                   
                  <div class="col-12" *ngIf = "!id">
                      <input class="inputbox"  placeholder="password" name="password"  type="{{type}}" [(ngModel)]="addForm.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" minlength="4" required >
                      <span class="material-icons" (click)="changeVisibility()">
                        {{Visibility}}
                        </span>
               <div
  [hidden]="password.valid || password.pristine"
  class="erro1"
>
  Password is required(Minimum 4 Character)
</div>
                    </div>


                    <p>Please provide customer address.</p>
                    <div class="col-12">
                        <input class="inputbox" name="houseNo" [(ngModel)]="addForm.houseNo"  #houseNo="ngModel" placeholder="Building No" [ngClass]="{ 'is-invalid': f.submitted && houseNo.invalid }"  >
                        <span class="material-icons" >
                          home
                          </span>
                        <div
    [hidden]="houseNo.valid || houseNo.pristine"
    class="erro1"
  >Building No is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="street" [(ngModel)]="addForm.street"  #street="ngModel" placeholder="Street" [ngClass]="{ 'is-invalid': f.submitted && street.invalid }"  >
                        <span class="material-icons" >
                          streetview
                          </span>
                        <div
    [hidden]="street.valid || street.pristine"
    class="erro1"
  >Street is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="landmark" [(ngModel)]="addForm.landmark"  #landmark="ngModel" placeholder="Landmark" [ngClass]="{ 'is-invalid': f.submitted && landmark.invalid }"  >
                        <span class="material-icons" >
                          bookmark
                          </span>
                        <div
    [hidden]="landmark.valid || landmark.pristine"
    class="erro1"
  >Landmark is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="city" [(ngModel)]="addForm.city"  #city="ngModel" placeholder="City / Town" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }"  >
                        <span class="material-icons" >
                          location_city
                          </span>
                        <div
    [hidden]="city.valid || city.pristine"
    class="erro1"
  >City is Required
  </div>
      
                      </div>


                  <div class="col-12">

              
          
                  <div class="col-12">
                </div>
           
                      <button mat-button [disabled]="f.invalid" class="submit" (click)="AddCustomer()">submit</button>
                <mat-slide-toggle class="toogle"
                      [(ngModel)]="addForm.adoptionStatus"
                      name="adoptionStatus"
                      >{{addForm.adoptionStatus ? 'Virtual Adoption Active' : 'Virtual Adoption Not Active'}}</mat-slide-toggle
                    >

                    
                  </div>    
                  
              </div> 
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 