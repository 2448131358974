import { Router, CanActivate } from '@angular/router';
import { usersService } from '../tikaUser/user.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  constructor(private apiService:usersService,
    private route:Router) { }
  
  canActivate():any{

    if(localStorage.getItem("userProfile")){
      if(!JSON.parse(localStorage.getItem("userProfile")).role){

        return true;
      }else{
    this.route.navigate(['/vendor/food/list']);

      }
  }
  else{
    this.route.navigate(['/doctor/login']);
    return false;
  }
}
}
