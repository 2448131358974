import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/crudService/client.service';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss'],
})
export class StaffListComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'type',
    'createdAt',
    'email',
    'approval',
  ];
  pageSize: number = 5;
  dataSource: any = new MatTableDataSource();
  constructor(private ClientService: ClientService, private route: Router) {}

  applyFilterGrid(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  ngOnInit(): void {
    this.dataSource.data = this.ClientService.clientStaffData || [];
    console.log('data47474', this.ClientService.clientStaffData);
    if (this.ClientService.clientStaffData?.length == 0) {
      this.route.navigate(['admin/client']);
    }
  }
}
