import { usersService } from '../../../services/tikaUser/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
addForm:any;
checkbox:boolean=false
Visibility:any='visibility_off'
type:string='password';icon:string="check_circle_outline";
icon1="check_circle"
disable:boolean=true;
  constructor(
    private r:Router,
    private toast:ToastComponent,
    private dialog:DialogComponent,
    public usersService:usersService
  ) {
    this.addForm={
      username:"",
      password:""
    }
    this.checkboxDatacheck();
   }

   checkboxDatacheck(){
    if(localStorage.getItem('checkbox')){
      this.checkbox = true;
      let data = JSON.parse(localStorage.getItem('checkbox'));
      this.addForm.password = data.password;
      this.addForm.username = data.username;
      this.checkbox = true;
      this.changeVisibility();
    }
  }

   changeVisibility()
   {
      if(this.Visibility=="visibility_off")
      {
        this.Visibility="visibility"
        this.type='text'
      }
      else if(this.Visibility=="visibility")
      {
        this.Visibility='visibility_off'
        this.type='password';
      }
   }
  ngOnInit(): void {
  }
  register(){
    this.r.navigate(['/admin/register'])
  }

  forgot(){
    this.r.navigate(['/admin/forgot'])
  }

  login()
  {
    let checkboxData={
      "password":this.addForm.password,
      "email":this.addForm.username,
    }
    // this.usersService.checkEmail({"email":this.addForm.username}).then((res:any)=>{
      // if(res == "Success"){
        this.usersService.login(checkboxData ,environment.api.admin).then((data:any) => {
          
          if(data.message == "Login Successfully" ){
            
            if(this.checkbox){
              localStorage.setItem('checkbox',JSON.stringify(checkboxData));
            }
           
            else{
              localStorage.removeItem('checkbox')
            }
            if(data.user.role == 'vendor'){
               this.r.navigate(['vendor/food/list'])
            }
            else{
            this.goToAdmin();
            }
          }
          // else if(data.user.role == 'vendor'){
          //   this.r.navigate(['list-inventory'])
          // }
          else{
            this.toast.toastError("Either email or password is wrong.");
            
          }
        })

        // else if(data.role == 'vendor'){
        //   this.r.navigate(['list-inventory'])
        // }
      // }else{
      //   this.toast.toastError(res);
      // }
  }

  goToAdmin(){
    this.r.navigate(['/admin/dashboard']);
  }
}
