import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { environment } from 'src/environments/environment';
import $ from "jquery";

@Component({
  selector: 'app-rx-view',
  templateUrl: './rx-view.component.html',
  styleUrls: ['./rx-view.component.scss']
})
export class RxViewComponent implements OnInit {
  
  dogModel: any;
  stores:any;
  selectedFiles: any;
  uploadflag: boolean = false;
  currentUpload: any;
  userData: any;
  dogId: any;
  id: any;
  showPopup: boolean = false;
  name: any;
  tab: any = 1;
  week: any = 5;
  repeat: any = 'Everyday';
  time: any = 'Morning';
  taken: any = 'Before food';
  allSuggestions: any;
  suggestions: any;
  user: any;
  dogDetail: any;
  focused: boolean = false;
  value: any;
  nextFalse: boolean = true;
  startDate: any;
  endDate: any;
  errorFlag: boolean = false;
  nextDates: any = [];
  popupType: any = 'Tablet';
  secondpopup: boolean = false;
  popupError: boolean = false;
  drug: boolean = true;
  syrup: boolean = false;
  powder: boolean = false;
  newVaccineFlag: boolean = false;
  allContacts: any = [];
  importedContacts: any = [];
  phone_Number: any = '';
  itemsObj: any;
  items: any;
  itemsflag: boolean = false;
  contactsarray: any = [];
  androidPlatform : boolean = false;
  contactName : any;
  newContactFlag : boolean = false;
  saveEnable : boolean = false;
  contactNameErrorFlag : boolean = false;
  audio : any;
  selectedAudioStatus : any  = "stop";
  audioInterval : any;
  audioDuration : any = 0 ;
  imgResultBeforeCompress:string;
  imgResultAfterCompress:string;
  timeSlots : any = [{title : "Morning" , status : true},{title : "Noon" , status : false},
                     {title : "Evening" , status : false}, {title : "Night" , status : false}]
  imageUrl: string;
 checkVar : boolean = false;

 
  constructor( 
    private activatedRoute : ActivatedRoute,
    private crudService : CrudServiceService, 
    private toast:ToastComponent,
    private route : Router) { 
      this.dogModel  = {
        medicines : []
      }
  
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    console.log(this.id)
    this.getAllSuggestion()
   
  }

  ngOnInit(): void {
  }
  addDrug() {
    // if (this.name) {
      let week = ' day ';

      if (this.week != 1) {
        week = ' days ';
      }
      let timeSlot;
      this.timeSlots.map((ele,i)=>{
       if(i == 0 && ele.status){
          timeSlot = "Morning"
       }else if(ele.status){
         if(timeSlot){
          timeSlot = timeSlot + " - "+ele.title;

         }else{
          timeSlot = ele.title;

         }
       }
      })
      let obj: any;
      if (this.popupType == 'Tablet') {
        obj = {
          name: this.name,
          description:
            this.tab +
            ' tablet ' +
            this.repeat +
            ' for ' +
            this.week +
            week +
            ' in ' +
            timeSlot +
            ', ' +
            this.taken +
            ' ',
          week: this.week,
        };
      } else if (this.popupType == 'Syrup') {
        obj = {
          name: this.name,
          description:
            this.tab +
            ' ml ' +
            this.repeat +
            ' for ' +
            this.week +
            week +
            ' in ' +
            timeSlot +
            ', ' +
            this.taken +
            ' ',
          week: this.week,
        };
      } else if (this.popupType == 'Powder') {
        obj = {
          name: this.name,
          description:
            this.tab +
            ' mg ' +
            this.repeat +
            ' for ' +
            this.week +
            week +
            ' in ' +
            timeSlot +
            ', ' +
            this.taken +

            ' ',
          week: this.week,
        };
       
      } console.log(obj)
      obj.newVaccineFlag = this.newVaccineFlag;

      if (!(this.dogModel.medicines && this.dogModel.medicines.length > 0)) {
        this.dogModel.medicines = [];
      }
      this.dogModel.medicines.push(obj);
      this.name = '';
      this.showPopup = false;
      this.secondpopup = false;
      this.suggestions = [];

      console.log(
        this.newVaccineFlag,
        this.tab,
        this.week,
        this.time,
        this.taken,
        this.repeat
      );
      this.week = 5;
     this.timeSlots  = [{title : "Morning" , status : true},{title : "Noon" , status : false},
      {title : "Evening" , status : false}, {title : "Night" , status : false}]
      this.stores = [this.newVaccineFlag,this.tab,this.week,this.time,this.taken,this.repeat]
    console.log(this.stores)
     

    // } else {
      // this.toast.presentToastError('Please enter the name of vaccine');
    // }
  }
  openPopup() {
    this.showPopup = !this.showPopup;
    this.newVaccineFlag = false;

    let self = this;
    $('.chb').change(function (res) {
      console.log(res.currentTarget.id);
      self.popupType = res.currentTarget.id;
      if (self.popupType == 'Tablet') {
        self.tab = 1;
      } else {
        self.tab = 25;
      }
      $('.chb').prop('checked', false);
      $(this).prop('checked', true);
    });
  }

  delete(i) {
    this.dogModel.medicines.splice(i, 1);
  }
  plusWeek() {
    if (this.week < 30) {
      this.week += 1;
    }
    console.log (this.week+"plus week")
  }
  minusWeek() {
    if (this.week > 1) {
      this.week -= 1;
    }
    console.log(this.week+"minus week")
  }
  plusTab() {
    if (this.popupType == 'Tablet') {
      if (this.tab < 30) {
        this.tab += 1;
      }
    } else if (this.popupType == 'Syrup') {
      if (this.tab < 200) {
        this.tab += 25;
      }
    } else if (this.popupType == 'Powder') {
      if (this.tab < 200) {
        this.tab += 25;
      }
    }
    console.log(this.tab+"plus tab")
  }
  minusTab() {
    if (this.tab > 1) {
      this.tab -= 1;
    }
    console.log(this.tab+"minus tab")
  }
  

rxtaken(take){
  if (take=="After food"){
    console.log("After food")
  }else {
    console.log("before food")
  }
}
   
  NeedClearity(){
console.log("in NeedClearity funtion")
this.crudService.edit({rxStatus:"need clearity",_id:this.id} , environment.api.vetPrescribe).then((res)=>{
  this.toast.toastSuccess("Customer details updated successfully.");
  this.route.navigate(['/admin/RxManager/list']);
  this.toast.toastSuccess("successfully");
  console.log("toastSuccess")
  })
  }

  Save(){
    console.log("in Save Function ")
    this.crudService.edit({rxStatus:"Save",_id:this.id} , environment.api.vetPrescribe).then((res)=>{
      this.toast.toastSuccess("Customer details updated successfully.");
      this.route.navigate(['/admin/RxManager/list']);
      this.toast.toastSuccess("successfully");
      console.log("toastSuccess")
      })
  }
  Translate(){
    console.log("in Translate Function")
    this.crudService.edit({rxStatus:"Translated",_id:this.id} , environment.api.vetPrescribe).then((res)=>{
      this.toast.toastSuccess("Customer details updated successfully.");
      this.route.navigate(['/admin/RxManager/list']);
      this.toast.toastSuccess("successfully");
      console.log("toastSuccess")
      })
  }
    
  updateTimeSlot(slot){
    let count = 0;
    let slots;
    this.timeSlots.map((ele)=>{
      if(ele.status){
        count += 1;
        slots = ele.title;
        console.log(ele.title
          )
      }
     
    })
    if(!(count==1 && slot.title == slots)){
      slot.status = !slot.status;
    } 
  }
  getAllSuggestion() {
    this.crudService
      .getAll(environment.api.vaccineSuggestion, { status: true })
      .then((res) => {
        this.allSuggestions = res;
        let suggestion = [];
        this.allSuggestions.map((element) => {
          element.strength.map((ele) => {
            suggestion.push(
              ele.name
                ? { name: element.name + ' (' + ele.name + ')' }
                : { name: element.name }
            );
          });
        });
        this.allSuggestions = suggestion;
      });
  }

  checkSuggestion() {
    if(this.name && this.name.length > 0){
 this.checkVar = true;
 this.suggestions = this.allSuggestions.filter((elem) => {
  return elem.name.toLowerCase().includes(this.name.toLowerCase());
});
if (this.name && !this.newVaccineFlag) {
  this.newVaccineFlag = true;
}

    }else{
 this.checkVar = false;

    }
    
  
  }
  
  selectSuggestion(name, event) {
    this.newVaccineFlag = false;
    event.stopPropagation();
    this.name = name;
    this.suggestions = [];
    console.log(this.name)
  }
 
}
