import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { usersService } from '../../../services/tikaUser/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { VetCustomersComponent } from '../../vet-customers/vet-customers.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-list-consulting',
  templateUrl: './list-consulting.component.html',
  styleUrls: ['./list-consulting.component.scss']
})
export class ListConsultingComponent implements OnInit {

  displayedColumns: string[] = ['userName', 'action'];
  dataSource :any = new MatTableDataSource;
  @Output() options:any = "data";
  maxperpage : number = 100;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  reserveData:any;
constructor(
  private apiService:usersService,
  private route:Router,
  private crudService : CrudServiceService,
  private dialog: DialogComponent,
  private matDialog : MatDialog
  ) {

}
  


   ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort=this.sort;
    this.getAllSubadmin()
  }

   getPageSizeOptions(): number[]{
    if(this.paginator.length>this.maxperpage)
    return [5, 10,50,this.maxperpage, this.dataSource.data.length];
    else
    return [5,10,50, this.maxperpage];
  }
  getAllSubadmin()
  {
       this.crudService.getAll(environment.api.remoteConsulting , {}).then((res:any)=>
      {
      console.log(res)
      this.reserveData = res;
      this.reserveData.map((elem)=>{
console.log('34444444444444',res)
      })
  
      this.dataSource.data=this.reserveData;
    })
  }


 
  deleteUser(element)
  {
    this.dialog.openDialog("Delete Consulting","Are you sure to delete the  Consulting?").then((isOkay)=>{
      if(isOkay){
    this.crudService.delete(element._id , environment.api.remoteConsulting).then((res)=>{
      this.crudService.edit({_id : element.vetId , consultAvailability : "available"} , environment.api.businessUser)
      this.getAllSubadmin();
  
    })
  }
})
  }

  ngOnInit(): void {
      }

    
}
