import { LoaderService } from './../loader/loader.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../core/constant';

import { DialogComponent } from '../../shared/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  public clientStaffData: any = [];

  setClientStaffData(data: any) {
    this.clientStaffData = data || [];
  }
  Save(arg0: { rxStatus: string; _id: any }, vetPrescribe: string) {
    throw new Error('Method not implemented.');
  }
  apiUrl: string = AppSettings.API_ENDPOINT;
  headers: any;
  accessToken: any;
  constructor(
    public http: HttpClient,
    public loadingService: LoaderService,
    public snackbarCmp: ToastComponent,
    public dialog: DialogComponent
  ) {
    this.headers = new Headers();
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.headers.append('Accept', 'application/json');
    this.headers.append('content-type', 'application/json');
  }

  addclient(data, model) {
    // console.log(data,'datata',model)
    this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model + '/add';
    return new Promise((resolve, reject) => {
      this.http.post(addRatingURL, data, { headers: this.headers }).subscribe(
        (res) => {
          this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        },
        (err) => {
          console.log(err);
          this.loadingService.hideLoading();
          this.snackbarCmp.toastError(err.error.message);
          reject(err);
        }
      );
    });
  }
  getAll(data: any, filterJSON) {
    let filter = encodeURI(JSON.stringify(filterJSON));

    this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + data + '/getall' + '?filter=' + filter;
    return new Promise((resolve, reject) => {
      this.http.get(addRatingURL, { headers: this.headers }).subscribe(
        (res) => {
          this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        },
        (err) => {
          this.loadingService.hideLoading();
          this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }

  getAllStaffByClinicId(data: any, clientId: any) {
    // this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + data + `/staff/get/${clientId}`;
    return new Promise((resolve, reject) => {
      this.http.get(addRatingURL, { headers: this.headers }).subscribe(
        (res) => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        },
        (err) => {
          //   this.loadingService.hideLoading();
          this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }

  getAllPatientByClinicId(filter: any) {
    // this.loadingService.showLoading();
    let filterUrl = encodeURI(JSON.stringify(filter));
    let addRatingURL = this.apiUrl + `customer` + '?filter=' + filterUrl;
    return new Promise((resolve, reject) => {
      this.http.get(addRatingURL, { headers: this.headers }).subscribe(
        (res) => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        },
        (err) => {
          //   this.loadingService.hideLoading();
          this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }
  getAllEnquiry() {
    // this.loadingService.showLoading();
    let addRatingURL = this.apiUrl + 'get/enquiry';
    return new Promise((resolve, reject) => {
      this.http.post(addRatingURL, {}, { headers: this.headers }).subscribe(
        (res) => {
          // this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        },
        (err) => {
          //   this.loadingService.hideLoading();
          this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }

  delete(id, model) {
    return new Promise((resolve, reject) => {
      this.loadingService.showLoading();

      let deleteRatingURL = this.apiUrl + model + '/' + id;
      this.http.delete(deleteRatingURL).subscribe(
        (res) => {
          this.loadingService.hideLoading();
          resolve(res);
        },
        (err) => {
          this.loadingService.hideLoading();
          this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }
  getDedtail(id, model, filterJSON) {
    return new Promise((resolve, reject) => {
      let filter = encodeURI(JSON.stringify(filterJSON));
      this.loadingService.showLoading();

      let deleteRatingURL =
        this.apiUrl + model + '/' + id + '?filter=' + filter;
      this.http.get(deleteRatingURL).subscribe(
        (res) => {
          this.loadingService.hideLoading();
          resolve(res);
        },
        (err) => {
          this.loadingService.hideLoading();
          this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }
  editClient(id, model, data) {
    this.loadingService.showLoading();

    let addRatingURL = this.apiUrl + model + '/' + id;
    return new Promise((resolve, reject) => {
      this.http.post(addRatingURL, data, { headers: this.headers }).subscribe(
        (res) => {
          this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        },
        (err) => {
          this.loadingService.hideLoading();
          this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
          reject(err);
        }
      );
    });
  }
}
