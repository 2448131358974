import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { usersService } from 'src/app/services/tikaUser/user.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';

@Component({
  selector: 'app-add-delivery',
  templateUrl: './add-delivery.component.html',
  styleUrls: ['./add-delivery.component.scss']
})
export class AddDeliveryComponent implements OnInit {


  addForm : any;
  id : any;
  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";

  constructor(private crudService : CrudServiceService,
    private activatedRoute : ActivatedRoute,
    private userService : usersService , 
    private toast : ToastComponent,
    private router : Router) { 

    this.addForm = {
      name : "",
      email : "",
      mobile : "",
      role : "delivery",
      approval : "Approved",
      status : true
    }

    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    if(this.id){
      this.crudService.getDedtail(this.id , environment.api.businessUser).then((res:any)=>{
        this.addForm = res;
      })
    }
  }

  changeVisibility()
  {
     if(this.Visibility=="visibility_off")
     {
       this.Visibility="visibility"
       this.type='text'
     }
     else if(this.Visibility=="visibility")
     {
       this.Visibility='visibility_off'
       this.type='password';
     }
  }

  AddCustomer(){
    console.log(this.addForm)
    if(this.addForm.name && this.addForm.email){
      if(this.id){
        this.crudService.edit(this.addForm , environment.api.businessUser).then((res)=>{
          this.toast.toastSuccess("Delivery Guy details updated successfully.");
          this.router.navigate(['/admin/delivery/list']);
          })
      }else{
        
        this.add();

    
      }
    }
    // this.toast.toastSuccess("Delivery Guy added successfully.");
  }
  add(){
    // this.addForm.password = (this.addForm.name.substring(0,2)).toUpperCase() + Math.floor(Math.random()*9000);
    // this.addForm.key = this.addForm.password;
    this.checkExistance();
  }

  ngOnInit(): void {
  }

  checkExistance():any{
  // this.crudService.checkExistanceDelivery({key : this.addForm.password}).then((res:any)=>{
    // if(res && res.message && res.message == "We can use this."){
      this.crudService.getAll(environment.api.businessUser , {mobile : this.addForm.mobile}).then((res : any)=>{
        if(res.length == 0 ){
          this.crudService.add(this.addForm , environment.api.businessUser).then((res)=>{
            this.toast.toastSuccess("Delivery Guy added successfully.");
            this.router.navigate(['/admin/delivery/list']);
            // return true;
    
            })
        }else{
     this.toast.toastError("Mobile Number is already used")
        }
      })
   

    // }else{
    //   this.add();
    //  return false;

    // }
  // })
  }


}
