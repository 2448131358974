
<div  class="mat-card card">

  <div class="row">
    <div class="col-8">
        <h2 class="main-heading">
          Manage Food
         
        </h2> 
    </div>  
    <div class="col-4">
      <button mat-button class="submit" (click)="addUser()">Add Food</button>
    </div>
  </div> 
  <div class="matTableDiv">
<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">


    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Brand </th>
      <td mat-cell *matCellDef="let element" id="brand">
        <ng-container *ngIf="element.brand == undefined || element.brand == '';else brand">NA</ng-container>
                    <ng-template #brand>{{element.brand}}</ng-template>
 </td>
    </ng-container>
    

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
      <td mat-cell *matCellDef="let element" id="name">
        <ng-container *ngIf="element.name == undefined || element.name == '';else name">NA</ng-container>
                    <ng-template #name>{{element.name}}</ng-template>
 </td>
    </ng-container>

    <ng-container matColumnDef="ProductId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Id </th>
      <td mat-cell *matCellDef="let element" id="ProductId">
        <ng-container *ngIf="element.ProductId == undefined || element.ProductId == '';else ProductId">NA</ng-container>
                    <ng-template #ProductId>{{element.ProductId}}</ng-template>
 </td>
    </ng-container>
   

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
      <td mat-cell *matCellDef="let element" id="price">
        <ng-container *ngIf="element.price == undefined || element.price == '';else price">NA</ng-container>
                    <ng-template #price>{{element.price}}</ng-template>
 </td>
    </ng-container>

  


    <ng-container matColumnDef="capacity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Capacity </th>
      <td mat-cell *matCellDef="let element" id="capacity">
        <ng-container *ngIf="element.capacity == undefined || element.capacity == '';else capacity">NA</ng-container>
                    <ng-template #capacity>{{element.capacity}}</ng-template>
 </td>
    </ng-container>


    <ng-container  matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef > Action </th>
     <td mat-cell *matCellDef="let element">
       <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span>
         <span class="material-icons editDelete" (click)="deleteUser(element._id)" title="Delete">delete</span>
 </td>
   </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns">

    </tr>
    <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">

    </tr>

  </table>
  <mat-paginator [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageEvent = $event">
</mat-paginator>
 </div>
</div>
