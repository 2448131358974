<div class="bg">
    <div class="containerFull">
        <div class="row d-flex justify-content-center box">
            <div>
                <router-outlet>

                </router-outlet>
            </div>
        </div>
    </div>
</div>