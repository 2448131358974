import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { usersService } from 'src/app/services/tikaUser/user.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { FileUpload } from 'src/app/shared/classes/file-upload';
@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  
  userId:any;
  selectedFiles: FileList;
  currentUpload: FileUpload;
  addForm : any;
  id : any;
  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";

  constructor(private crudService : CrudServiceService,
    private activatedRoute : ActivatedRoute,
    private userService : usersService , 
    private snackbar : ToastComponent,
    private toast : ToastComponent,
    private router : Router) { 
      this.userId = JSON.parse(localStorage.getItem('userProfile'));
    this.addForm = {
      city : "",
      status : true,
    }

  
  }


  AddCustomer(){
    console.log(this.addForm)
    if(this.addForm.city ){
      this.crudService.add(this.addForm , environment.api.location).then((res)=>{
        this.toast.toastSuccess("Location created successfully.");
        this.router.navigate(['/admin/location/list']);

        })
    }
    // this.toast.toastSuccess("Delivery Guy added successfully.");
  }


  ngOnInit(): void {
  }



}