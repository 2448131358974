import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-vaccines',
  templateUrl: './add-vaccines.component.html',
  styleUrls: ['./add-vaccines.component.scss']
})
export class AddVaccinesComponent implements OnInit {


  addForm : any;
  id : any;

  constructor(private crudService : CrudServiceService,
    private activatedRoute : ActivatedRoute,
    private toast : ToastComponent,
    private router : Router) {
      this.addForm = {
        name : "",
        strength : [{name : ""}],
        company : "",
        status : false
      }
      this.id = this.activatedRoute.snapshot.paramMap.get("id");
      if(this.id){
        this.crudService.getDedtail(this.id , environment.api.vaccineSuggestion).then((res)=>{
          this.addForm = res;
        })
      }
     }

     AddBreed(){
       if(this.addForm.name ){
         if(this.id){
           this.crudService.edit(this.addForm , environment.api.vaccineSuggestion).then((res)=>{
             this.router.navigate(['/admin/vaccineSuggestion/list']);
             this.toast.toastSuccess("Vaccine details updated successfully")
           })
         }else{
          this.crudService.add(this.addForm , environment.api.vaccineSuggestion).then((res)=>{
            this.router.navigate(['/admin/vaccineSuggestion/list']);
            this.toast.toastSuccess("Vaccine added successfully")
          })
         }
       }
     }

     AddStrength(){
      this.addForm.strength.push({name : "" })
    }
    removeIcon(i){
      this.addForm.strength.splice(i,1);
    }

  ngOnInit(): void {
  }

}
