import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { usersService } from 'src/app/services/tikaUser/user.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  constructor(
    private r:Router,
    private userService:usersService
  ) { }

  ngOnInit(): void {
  }
  myAccount(){
    this.r.navigate(['/admin/profile']);
  }

  changePassword(){
    this.r.navigate(['/admin/changepassword']);
  }
  
  logout(){
    // this.userService.logout().then((res)=>{
    //   console.log(res);
      this.r.navigate(['/admin/login']);
      localStorage.removeItem("userProfile")
    // })
  }
}
