import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { usersService } from '../../../services/tikaUser/user.service';
import { MatPaginator } from '@angular/material/paginator';
import {PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-list-accessories',
  templateUrl: './list-accessories.component.html',
  styleUrls: ['./list-accessories.component.scss']
})
export class ListAccessoriesComponent implements OnInit {

  displayedColumns: string[] = ['brand','name','ProductId','price', 'capacity' ,'action' ];
  dataSource :any = new MatTableDataSource;
  @Output() options:any = "data";
  maxperpage : number = 100;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  reserveData:any;
  searchVariable:any;
  searchFlag:boolean = false;
  refreshFlag:boolean = false;
  searchHelperFlag:boolean = false;
  userId: any;
  reserveDataForFilter: any;
constructor(
  private apiService:usersService,
  private route:Router,
  private crudService : CrudServiceService,
  private dialog: DialogComponent,
  private matDialog : MatDialog
  ) {
    this.userId = JSON.parse(localStorage.getItem('userProfile'));
}

   ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort=this.sort;
    this.getAllSubadmin()
  }

   getPageSizeOptions(): number[]{
    if(this.paginator.length>this.maxperpage)
    return [5, 10,50,this.maxperpage, this.dataSource.data.length];
    else
    return [5,10,50, this.maxperpage];
  }
  getAllSubadmin()
  {
       this.crudService.getAll(environment.api.accessories ,{vendorId : this.userId._id}).then((res:any)=>
      {
      console.log(res)
      this.reserveData = res;
     
      this.reserveData.map((elem)=>{
console.log('34444444444444',res)
      })
  
      this.dataSource.data=this.reserveData;
    })
  }
  addUser()
  {
    this.route.navigate(['vendor/accessories/add'])
  }


edit(id)
{
  this.route.navigate(['vendor/accessories/edit',id])
}
deleteUser(id)
{
  this.dialog.openDialog("Delete Accessories ","Are you sure to delete the Accessories?").then((isOkay)=>{
    if(isOkay){
  this.crudService.delete(id , environment.api.accessories).then((res)=>{
    this.getAllSubadmin();
  
  })
    }
  })

}

  ngOnInit(): void {
      }

  
 
    }
