<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading" *ngIf = "!id">Add Business User</h2>  
                      <h2 class="heading" *ngIf = "id">Edit Business User</h2>  

                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="name" [(ngModel)]="addForm.name"  value="" #name="ngModel" placeholder="FullName" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
                      <div
  [hidden]="name.valid || name.pristine"
  class="erro1"
>Name is Required
</div>
    
                    </div>


                    <div class="col-12">
                      <input class="inputbox" name="mobile" [(ngModel)]="addForm.mobile"  value="" #mobile="ngModel" placeholder="Mobile" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required >
                      <span class="material-icons" >
                        phone
                        </span>
                      <div
  [hidden]="mobile.valid || mobile.pristine"
  class="erro1"
>Mobile is Required
</div>
    
                    </div>


                  <div class="col-12">
                    <input class="inputbox" placeholder="Email" name="email" [(ngModel)]="addForm.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email >
                    <span class="material-icons" >
                        email
                        </span>
                    <div
                    [hidden]="email.valid || email.pristine"
                    class="erro1"
                  >
                    <div *ngIf="email.errors?.required">Email is required</div>
                    <div *ngIf="email.errors?.email">
                      Email must be a valid email address
                    </div></div>
            </div> 
          

                <!-- <div class = "col-12">
                    <mat-checkbox class="example-margin"
                    [(ngModel)] = "addForm.veterinarian"
                    #veterinarian = "ngModel"
                    name = "veterinarian"
                     >     
                    Are you a veterinarian?
                   </mat-checkbox>
                </div>

                <div *ngIf = "addForm.veterinarian">
                    <p>Please provide your clinic address to give directions to your customers.</p>
                    <div class="col-12">
                        <input class="inputbox" name="houseNo" [(ngModel)]="veterinarianAddress.houseNo"  #houseNo="ngModel" placeholder="Building No" [ngClass]="{ 'is-invalid': f.submitted && houseNo.invalid }" required >
                        <span class="material-icons" >
                          home
                          </span>
                        <div
    [hidden]="houseNo.valid || houseNo.pristine"
    class="erro1"
  >Building No is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="street" [(ngModel)]="veterinarianAddress.street"  #street="ngModel" placeholder="Street" [ngClass]="{ 'is-invalid': f.submitted && street.invalid }" required >
                        <span class="material-icons" >
                          streetview
                          </span>
                        <div
    [hidden]="street.valid || street.pristine"
    class="erro1"
  >Street is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="landmark" [(ngModel)]="veterinarianAddress.landmark"  #landmark="ngModel" placeholder="Landmark" [ngClass]="{ 'is-invalid': f.submitted && landmark.invalid }" required >
                        <span class="material-icons" >
                          bookmark
                          </span>
                        <div
    [hidden]="landmark.valid || landmark.pristine"
    class="erro1"
  >Landmark is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="city" [(ngModel)]="veterinarianAddress.city"  #city="ngModel" placeholder="City / Town" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required >
                        <span class="material-icons" >
                          location_city
                          </span>
                        <div
    [hidden]="city.valid || city.pristine"
    class="erro1"
  >City is Required
  </div>
      
                      </div>

                    </div>

                <div class = "col-12">
                    <mat-checkbox class="example-margin"
                    [(ngModel)] = "addForm.visitation"
                    #visitation = "ngModel"
                    name = "visitation"
                     >     
                    Will you do home visitation?
                   </mat-checkbox>
                </div>

                <div class = "col-12">
                    <mat-checkbox class="example-margin"
                    [(ngModel)] = "addForm.supplyFood"
                    #supplyFood = "ngModel"
                    name = "supplyFood"
                     >     
                    Do you supply dog food?
                   </mat-checkbox>
                </div>

                <div *ngIf = "addForm.supplyFood">
                    <p>Please provide your dog food address to give directions to your customers.</p>

                    <div class="col-12">
                        <input class="inputbox" name="houseNo1" [(ngModel)]="dogFoodAddress.houseNo"  #houseNo1="ngModel" placeholder="Building No" [ngClass]="{ 'is-invalid': f.submitted && houseNo1.invalid }" required >
                        <span class="material-icons" >
                          home
                          </span>
                        <div
    [hidden]="houseNo1.valid || houseNo1.pristine"
    class="erro1"
  >Building No is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="street1" [(ngModel)]="dogFoodAddress.street"  #street1="ngModel" placeholder="Street" [ngClass]="{ 'is-invalid': f.submitted && street1.invalid }" required >
                        <span class="material-icons" >
                          streetview
                          </span>
                        <div
    [hidden]="street1.valid || street1.pristine"
    class="erro1"
  >Street is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="landmark1" [(ngModel)]="dogFoodAddress.landmark"  #landmark1="ngModel" placeholder="Landmark" [ngClass]="{ 'is-invalid': f.submitted && landmark1.invalid }" required >
                        <span class="material-icons" >
                          bookmark
                          </span>
                        <div
    [hidden]="landmark1.valid || landmark1.pristine"
    class="erro1"
  >Landmark is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="city1" [(ngModel)]="dogFoodAddress.city"  #city1="ngModel" placeholder="City / Town" [ngClass]="{ 'is-invalid': f.submitted && city1.invalid }" required >
                        <span class="material-icons" >
                          location_city
                          </span>
                        <div
    [hidden]="city1.valid || city1.pristine"
    class="erro1"
  >City is Required
  </div>
      
                      </div>

                    </div>

                <div class = "col-12">
                    <mat-checkbox class="example-margin"
                    [(ngModel)] = "addForm.supplyAccesories"
                    #supplyAccesories = "ngModel"
                    name = "supplyAccesories"
                     >     
                    Are like to sell dog accesories?
                   </mat-checkbox>
                </div>
                <div *ngIf = "addForm.supplyAccesories">
                    <p>Please provide your dog food address to give directions to your customers.</p>

                    <div class="col-12">
                        <input class="inputbox" name="houseNo2" [(ngModel)]="supplierAddress.houseNo"  #houseNo2="ngModel" placeholder="Building No" [ngClass]="{ 'is-invalid': f.submitted && houseNo2.invalid }" required >
                        <span class="material-icons" >
                          home
                          </span>
                        <div
    [hidden]="houseNo2.valid || houseNo2.pristine"
    class="erro1"
  >Building No is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="street2" [(ngModel)]="supplierAddress.street"  #street2="ngModel" placeholder="Street" [ngClass]="{ 'is-invalid': f.submitted && street2.invalid }" required >
                        <span class="material-icons" >
                          streetview
                          </span>
                        <div
    [hidden]="street2.valid || street2.pristine"
    class="erro1"
  >Street is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="landmark2" [(ngModel)]="supplierAddress.landmark"  #landmark2="ngModel" placeholder="Landmark" [ngClass]="{ 'is-invalid': f.submitted && landmark2.invalid }" required >
                        <span class="material-icons" >
                          bookmark
                          </span>
                        <div
    [hidden]="landmark2.valid || landmark2.pristine"
    class="erro1"
  >Landmark is Required
  </div>
      
                      </div>


                      <div class="col-12">
                        <input class="inputbox" name="city2" [(ngModel)]="supplierAddress.city"  #city2="ngModel" placeholder="City / Town" [ngClass]="{ 'is-invalid': f.submitted && city2.invalid }" required >
                        <span class="material-icons" >
                          location_city
                          </span>
                        <div
    [hidden]="city2.valid || city2.pristine"
    class="erro1"
  >City is Required
  </div>
      
                      </div>

                    </div> -->
             <div class = "col-12">
               <p>{{veterinarianAddress?.formatted_address}}</p>
               </div>
                  <div class="col-12">
            
          
                  <div class="col-12">
                </div>
           
                      <button mat-button [disabled]="f.invalid" class="submit" (click)="AddSubAdmin()">submit</button>
                      <mat-slide-toggle class="toogle"
                      (ngModelChange) = "checkStatus()"
                      [(ngModel)]="status"
                      name="status"
                      >{{addForm.approval}}</mat-slide-toggle
                    >


                    <mat-slide-toggle class="toogle"
                    [(ngModel)]="available"
                    (ngModelChange) = "checkAvail()"

                    name="available"
                    >{{addForm.available}}</mat-slide-toggle
                  >


                  </div>    
                  
              </div> 
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 