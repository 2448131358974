<ul class="dropdown">
    <li (click)="myAccount()"> 
        <mat-icon  class="material-icons">account_circle</mat-icon>
        My account
    </li>
    <li (click)="changePassword()">
        <mat-icon class="material-icons">settings</mat-icon>
        Change Password
    </li>
    <li (click)="logout()"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="24" height="24"
        viewBox="0 0 226 226"
        style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#8f9bb3"><path d="M75.33333,18.83333c-10.40542,0 -18.83333,8.42792 -18.83333,18.83333v150.66667c0,10.40542 8.42792,18.83333 18.83333,18.83333h75.33333c10.40542,0 18.83333,-8.42792 18.83333,-18.83333v-65.91667h-65.91667v-18.83333h65.91667v-65.91667c0,-10.40542 -8.42792,-18.83333 -18.83333,-18.83333zM169.5,103.58333v18.83333h18.83333v28.25l37.66667,-37.66667l-37.66667,-37.66667v28.25z"></path></g></g></svg>
     log out</li>
     <span class="material-icons icon-arrow">
        arrow_drop_up
        </span>
</ul>