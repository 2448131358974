<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading" *ngIf = "!id">Add Vendor</h2>  
                      <h2 class="heading" *ngIf = "id">Edit Vendor</h2>  

                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="name" [(ngModel)]="addForm.name"  value="" #name="ngModel" placeholder="FullName" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
                      <div
  [hidden]="name.valid || name.pristine"
  class="erro1"
>Name is Required
</div>
    
                    </div>


                    <div class="col-12">
                      <input class="inputbox" name="mobile" [(ngModel)]="addForm.mobile"  value="" #mobile="ngModel" placeholder="Mobile" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required >
                      <span class="material-icons" >
                        phone
                        </span>
                      <div
  [hidden]="mobile.valid || mobile.pristine"
  class="erro1"
>Mobile is Required
</div>
    
                    </div>


                  <div class="col-12">
                    <input class="inputbox" placeholder="Email" name="email" [(ngModel)]="addForm.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email >
                    <span class="material-icons" >
                        email
                        </span>
                    <div
                    [hidden]="email.valid || email.pristine"
                    class="erro1"
                  >
                    <div *ngIf="email.errors?.required">Email is required</div>
                    <div *ngIf="email.errors?.email">
                      Email must be a valid email address
                    </div></div>
            </div> 
            <div  *ngIf="!id" class="col-12">
              <input class="inputbox" type="password" name="password" [(ngModel)]="addForm.password"  value="" #password="ngModel" placeholder="Enter Password" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required >
              <span  class="material-icons" >
                password
                </span>
              <div
[hidden]="password.valid || password.pristine"
class="erro1"
>password is Required
</div>

            </div>
        <!-- <div><ion-icon name="add-circle-outline"></ion-icon>sdfghjhgdfs</div>     -->
         
<!-- <div  class="row" *ngFor = "let item of itemArray ; let i = index" >
  <div class="col-4">
    <input class="inputbox" id="" type="text" name="brandName" [(ngModel)]="addForm.brandName{{i+1}}"  value="" #brandName="ngModel" placeholder="Brand Name" [ngClass]="{ 'is-invalid': f.submitted && brandName.invalid }" required >
    
    <div
[hidden]="brandName.valid || brandName.pristine"
class="erro1"
>Brand Name is Required
</div>

  </div>
  <div class="col-4">
    <input class="inputbox" id=""  type="text" name="discount" [(ngModel)]="addForm.discount"  value="" #discount="ngModel" placeholder="Enter Discount" [ngClass]="{ 'is-invalid': f.submitted && discount.invalid }" required >
    
    <div
[hidden]="discount.valid || discount.pristine"
class="erro1"
> discount is Required
</div>

  </div>
  <div class="col-3">
    <input class="inputbox" type="text" name="customerDiscount" [(ngModel)]="addForm.customerDiscount"  value="" #customerDiscount="ngModel" placeholder="Customer Discount " [ngClass]="{ 'is-invalid': f.submitted && customerDiscount.invalid }" required >
    
    <div
[hidden]="customerDiscount.valid || customerDiscount.pristine"
class="erro1"
>customer discount is Required
</div>

  </div>
  <button  *ngIf = "i == itemArray.length-1" (click)="AddBrand()" style="border-radius: 40px;height: 31px;
  width: 32px;
  margin-top: 3px;
  border: 1px;
  font-weight: 900;
  font-size: 20px;"> +</button> 
</div>
                -->

                <div class = "row" *ngFor = "let item of itemArray ; let i = index" style="display: block; padding: 0px 16px;">
                <div class="row">
                    
                                  <div class="col-3">
                                   <input class="inputbox" id="" type="text" name="brandName{{i+1}}" [(ngModel)]="item.brandName"  value="" #brandName="ngModel" placeholder="Brand Name">
                                </div>
                  

                                <div class="col-4">
                                  <input class="inputbox" id=""  type="number" name="discount{{i+1}}" [(ngModel)]="item.discount"  value="" #discount="ngModel" placeholder="Vendor Discount">

                               </div>
                  

                                  <div class="col-4" style="display: flex;">

                                    <input class="inputbox" id="" type="number" name="customerDiscount{{i+1}}" [(ngModel)]="item.customerDiscount"  value="" #customerDiscount="ngModel" placeholder="Customer Discount">
                                   <button class="addBrands"  *ngIf = "i == itemArray.length-1" (click)="addItem()" > +</button> 
                  </div>
                    </div>
</div>

                  <div class="col-12">
              
          
                  <div class="col-12">
                </div>
           
                      <button mat-button [disabled]="f.invalid" class="submit" (click)="AddSubAdmin()">submit</button>
                      <mat-slide-toggle class="toogle"
                      (ngModelChange) = "checkStatus()"
                      [(ngModel)]="status"
                      name="status"
                      >{{addForm.approval}}</mat-slide-toggle
                    >

<!-- 
                    <mat-slide-toggle class="toogle"
                    [(ngModel)]="available"
                    (ngModelChange) = "checkAvail()"

                    name="available"
                    >{{addForm.available}}</mat-slide-toggle
                  > -->


                  </div>    
                </div>
             
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 