<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading">Change Password</h2>  
                  </div>  
                  <div class="inputarea">
                


                  <div class="col-12">
                      <input class="inputbox" type="password" name="password" [(ngModel)]="addForm.current" placeholder="Current Password" #password="ngModel" required>
                      <span class="material-icons" >
                        face
                        </span>
                    </div>
              
                    <div class="col-12">
                        <input class="inputbox" type="password" name="passwordnew" [(ngModel)]="addForm.new" placeholder="New Password" #passwordnew="ngModel" required>
                        <span class="material-icons" >
                          face
                          </span>
                      </div>
                      <div class="col-12">
                        <input class="inputbox" type="password" name="passwordcurrent" [(ngModel)]="addForm.confirm" placeholder="Confirm Password" #passwordcurrent="ngModel" required>
                        <span class="material-icons" >
                          face
                          </span>
                      </div>
                 
          
                  <div class="col-12">
                      <button mat-button class="submit" (click)="changePassword()">Update</button>
                </div>
           
                  </div>    
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 