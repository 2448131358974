import { LoaderService } from './../loader/loader.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../../core/constant";

import { DialogComponent } from "../../shared/dialog/dialog.component";

@Injectable({
  providedIn: 'root'
})
export class CrudServiceService {
  Save(arg0: { rxStatus: string; _id: any; }, vetPrescribe: string) {
    throw new Error('Method not implemented.');
  }
  apiUrl : string = AppSettings.API_ENDPOINT;
	headers : any;
	accessToken : any;
  constructor(
	public http: HttpClient,
  	public loadingService : LoaderService,
  	public snackbarCmp : ToastComponent,
  	public dialog : DialogComponent,

  	) {
    this.headers = new Headers();
		this.headers.append('Access-Control-Allow-Origin' , '*');
		this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
		this.headers.append('Accept','application/json');
		this.headers.append('content-type','application/json');
		;
	  }
	  

	  getLoginUserData(){
		let loginUserData = JSON.parse(localStorage.getItem("userProfile"));
		if(loginUserData){
		  this.accessToken = loginUserData.token;
		}
		
	  }
	
	  getDedtail(id,model){
	
		this.getLoginUserData();
		this.loadingService.showLoading();
	
		let RatingURL = this.apiUrl + model+'/' +id
					  + '?access_token=' + this.accessToken
	
		return new Promise((resolve, reject) => {
		  this.http.get(RatingURL).subscribe(res => {
		  
			this.loadingService.hideLoading();
				resolve(res);
		  },(err)=>{
			this.loadingService.hideLoading();
				this.snackbarCmp.toastSuccess(AppSettings.GENERIC_ERROR);
				reject(err);
		  });
		});
	  }
	  count(model,filterJSON){
		this.getLoginUserData();
		
		let filter = encodeURI(JSON.stringify(filterJSON));
	  
		this.loadingService.showLoading();
	  
		let addRatingURL = this.apiUrl + model  + '/count' + '?filter=' + filter;
		return new Promise((resolve, reject) => {
	  
		this.http.get(addRatingURL)
		  .subscribe(res => {
			this.loadingService.hideLoading();

		  resolve(res);
		  }, (err) => {
		  this.loadingService.hideLoading();
		  this.snackbarCmp.toastSuccess(AppSettings.GENERIC_ERROR);
		//   this.toast.presentToastError(AppSettings.GENERIC_ERROR);
		  reject(err);
		  });
		});
	  }
	  getAll(model,filterJSON){
		this.getLoginUserData();
	  
	  let filter = encodeURI(JSON.stringify(filterJSON));
	
	  this.loadingService.showLoading();
	
	
	  let allRatingURL = this.apiUrl + model+'/' 
					+ '?filter=' + filter;
	  
	
	  return new Promise((resolve, reject) => {
		this.http.get(allRatingURL).subscribe((res:any) => {
		  
			this.loadingService.hideLoading();
			resolve(res);
		  
		},(err)=>{
		  this.loadingService.hideLoading();
		  this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
		  reject(err);
		});
	  });
	}
	
	add(info , model ) {
	  this.getLoginUserData();
		this.loadingService.showLoading();
	
		let addRatingURL = this.apiUrl + model + '/' 
	  return new Promise((resolve, reject) => {
	
		this.http.post(addRatingURL, info,{headers:this.headers})
		  .subscribe(res => {
			  this.loadingService.hideLoading();
			  // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
			  // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
			resolve(res);
		  }, (err) => {
			this.loadingService.hideLoading();
			this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
			reject(err);
		  });
	  });
	}
	



	editOrder(info,model) {
		this.getLoginUserData();
	this.loadingService.showLoading();
	


	let editRatingURL = this.apiUrl + model+'/';
  
  return new Promise((resolve, reject) => {

	this.http.patch(editRatingURL, info,{headers:this.headers})
	  .subscribe(res => {
		  this.loadingService.hideLoading();
		resolve(res);
	  }, (err) => {
		this.loadingService.hideLoading();
		this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
		reject(err);
	  });
  });
}

updateStatus(info) {
	this.getLoginUserData();
this.loadingService.showLoading();



let editRatingURL = this.apiUrl + "order/statusUpdate";

return new Promise((resolve, reject) => {

this.http.patch(editRatingURL, info,{headers:this.headers})
  .subscribe(res => {
	  this.loadingService.hideLoading();
	resolve(res);
  }, (err) => {
	this.loadingService.hideLoading();
	this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
	reject(err);
  });
});
}
	
	
	edit(info,model) {
			this.getLoginUserData();
		this.loadingService.showLoading();
		
	
	
		let editRatingURL = this.apiUrl + model+'/';
	  
	  return new Promise((resolve, reject) => {
	
		this.http.patch(editRatingURL, info,{headers:this.headers})
		  .subscribe(res => {
			  this.loadingService.hideLoading();
			resolve(res);
		  }, (err) => {
			this.loadingService.hideLoading();
			this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
			reject(err);
		  });
	  });
	}
	
	delete(id,model){
			this.getLoginUserData();
			return new Promise((resolve, reject) => {   
	  // this.dialog.openDialog(dialogTitle,dialogContent).then((isOkay)=>{
	  //       if(isOkay){
			this.loadingService.showLoading();
			
			let deleteRatingURL = this.apiUrl + model + '/' + id + '?access_token=' + this.accessToken;
			this.http.delete(deleteRatingURL)
			  .subscribe(res => {
				  this.loadingService.hideLoading();
				resolve(res);
			  }, (err) => {
				this.loadingService.hideLoading();
				this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
				reject(err);
			 });
	
	  })
		
		
	}

	deleteNearbyList(){
		this.getLoginUserData();
		return new Promise((resolve, reject) => {   
  // this.dialog.openDialog(dialogTitle,dialogContent).then((isOkay)=>{
  //       if(isOkay){
		this.loadingService.showLoading();
		
		let deleteRatingURL = this.apiUrl + "nearbyVetDeleteAll" + '?access_token=' + this.accessToken;
		this.http.delete(deleteRatingURL)
		  .subscribe(res => {
			  this.loadingService.hideLoading();
			resolve(res);
		  }, (err) => {
			this.loadingService.hideLoading();
			this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
			reject(err);
		 });
		// }
		// });

  })
	
	
}


	checkExistanceDelivery(info  ) {
		this.getLoginUserData();
		  this.loadingService.showLoading();
	  
		  let addRatingURL = this.apiUrl + "delivery/checkExistance" 
		return new Promise((resolve, reject) => {
	  
		  this.http.post(addRatingURL, info)
			.subscribe(res => {
				this.loadingService.hideLoading();
				// this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
				// this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
			  resolve(res);
			}, (err) => {
			  this.loadingService.hideLoading();
			  this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
			  reject(err);
			});
		});
	  }

	  getVetWithCustomers(){
		this.getLoginUserData();
	  
	  this.loadingService.showLoading();
	
	
	  let allRatingURL = this.apiUrl + 'businessUserWithCustomers'
	
	  return new Promise((resolve, reject) => {
		this.http.get(allRatingURL).subscribe((res:any) => {
		  
			this.loadingService.hideLoading();
			resolve(res);
		  
		},(err)=>{
		  this.loadingService.hideLoading();
		  this.snackbarCmp.toastError(AppSettings.GENERIC_ERROR);
		  reject(err);
		});
	  });
	}
	
}
