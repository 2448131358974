import { Router } from '@angular/router';
import { usersService } from '../../../services/tikaUser/user.service';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { MatMonthView } from '@angular/material/datepicker';
@Component({
  selector: 'app-list-payment',
  templateUrl: './list-payment.component.html',
  styleUrls: ['./list-payment.component.scss']
})
export class ListPaymentComponent implements OnInit {

  displayedColumns: string[] = ['doctorName','customerName','amount','for','date','payByAdmin', 'paid' ];
  dataSource :any = new MatTableDataSource;
  @Output() options:any = "data";
  maxperpage : number = 100;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  reserveData:any;
  todayDate: Date;
  reserveDatas: any;
constructor(
  private apiService:usersService,
  private route:Router,
  private snackbar : ToastComponent,
  private crudService : CrudServiceService,

  ) {

}

  //search Start


  searchVariable:any;
  searchFlag:boolean = false;
  searchHelperFlag:boolean = false;
  refreshFlag:boolean = false;
  showSearch(){
    if(this.searchHelperFlag == true){
      this.hideSearch()
    }else{
      this.searchHelperFlag = true;
      setTimeout(() => {
        this.searchFlag = true;
      }, 200);
    }
  }
  hideSearch(){
    this.searchFlag = false;
    setTimeout(() => {
      this.searchHelperFlag = false;
    }, 200);
  }

   searchComplete(){
     let userId = JSON.parse(localStorage.getItem('userProfile')).id;
     console.log(this.searchVariable);
     let filter = {
      "where":{
        "and":[
          {
            "realm":"user"
          },
          {
            "or":[
              {"customerName":{like:this.searchVariable,"options":"i"}},
              {"doctorName":{like:this.searchVariable,"options":"i"}},
              {"for":{like:this.searchVariable,"options":"i"}},
            ]
          }
        ],
       
      }
     }
      this.apiService.getAllUsersBySearch(filter).then((res:any)=>{
        console.log(res);
        this.dataSource.data = res;
        this.reserveDataForFilter = res;

        if(this.searchVariable !== undefined && this.searchVariable !== ''){
          this.refreshFlag = true;
        }else{
          this.refreshFlag = false;
        }
        setTimeout(() => {
          if(!res.length){
            this.apiService.snackbarCmp.toastError("Data is not found related to search : "+this.searchVariable)
          }
        }, 100);
      })
     this.hideSearch();

   }

   refreshData(){
     this.dataSource.data = this.reserveData;
     this.reserveDataForFilter = this.reserveData;
     this.searchVariable = undefined;
     this.refreshFlag = false;
   }
  


//search End




//filter
filter:any = {
  "status":"none",
  "role":"none",
  "startDate":"",
  "endDate":""
}
filterFlag:boolean = false;
filterFlagHelper:boolean =false;
reserveDataForFilter:any;

showFilter(){
  if(this.filterFlagHelper == true){
    this.hideFilter()
  }else{
    this.filterFlagHelper = true;
    setTimeout(() => {
      this.filterFlag = true;
    }, 200);
  }
}
hideFilter(){
  this.filterFlag = false;
  setTimeout(() => {
    this.filterFlagHelper = false;
  }, 200);
}

resetData(){
  this.filter = {
    "status":"none",
    "role":"none",
    "startDate":"",
    "endDate":""
  }
  this.dataSource.data = this.reserveDataForFilter;
  this.hideFilter();

}





// filter End


   ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort=this.sort;
    this.getAllSubadmin()
  }

   getPageSizeOptions(): number[]{
    if(this.paginator.length>this.maxperpage)
    return [5, 10,50,this.maxperpage, this.dataSource.data.length];
    else
    return [5,10,50, this.maxperpage];
  }
  getAllSubadmin()
  {
    this.crudService.getAll(environment.api.payments , {}).then((res:any)=>
    {
      console.log(res)
      this.reserveDatas = res;
   
      this.reserveDatas.map((element)=>{
        element.month =new Date(element.date).getMonth();
        element.day =new Date(element.date).getDate();
        element.year =new Date(element.date).getFullYear();

      })
  
      this.reserveDataForFilter = res;
      this.dataSource.data=res;
    })
  }
 
  ngOnInit(): void {
    this.crudService.getAll(environment.api.payments , {}).then((res:any)=>
    {

    })


    

    
      }


  editPayment(id){
    let date = new Date();
console.log(date,'1234567653421345675432');
    this.crudService.edit( {_id:id, "payByAdmin":date}, environment.api.payments).then((res:any)=>{
      this.snackbar.toastSuccess("Status Updated  successfully.")
      this.getAllSubadmin();
    
    })
      }

}
