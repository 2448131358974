import { AppSettings } from './../../../core/constant';
import { Router, ActivatedRoute } from '@angular/router';
import { usersService } from '../../../services/tikaUser/user.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { element } from 'protractor';
import { ToastComponent } from 'src/app/shared/toast/toast.component';

@Component({
  selector: 'app-add-business-user',
  templateUrl: './add-business-user.component.html',
  styleUrls: ['./add-business-user.component.scss']
})
export class AddBusinessUserComponent implements OnInit {
  addForm:any;
  date=new Date();
  veterinarianAddress : any;
  dogFoodAddress : any;
  supplierAddress : any;
  status : boolean = true;
  available : boolean = true;

  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";
  id:any;

    constructor(private ApiService:usersService,
      private router:Router,
      private userService : usersService ,
      private crudService : CrudServiceService,
      private snackbar : ToastComponent,
      private activatedRoute : ActivatedRoute,
      ) { 
        this.id = this.activatedRoute.snapshot.paramMap.get("id");
      this.addForm=
      {name : "",
        approval: "Approved",
        available: "Available",
        password:'',
        mobile : "",
        email:'',
        createdAt:this.date,
        veterinarian : true,
        visitation : false,
        supplyFood : false,
        supplyAccesories : false,
        registrationType : "admin",
        role : "vet"
      },

    

      this.dogFoodAddress = {
        houseNo : "",
        street : '',
        landmark : "",
        city : "",
        addressType : "dogFood"
      }

      this.supplierAddress = {
        houseNo : "",
        street : '',
        landmark : "",
        city : "",
        addressType : "supplyAccesories"

      }

      this.veterinarianAddress = {
        houseNo : "",
        street : '',
        landmark : "",
        city : "",
        addressType : "veterinarian"

      }

      if(this.id){
        this.crudService.getDedtail(this.id , environment.api.businessUser).then((res:any)=>{
        if(res && res._id){
          this.addForm = res;
          console.log('2342342342343434' ,this.addForm)
          if(this.addForm.approval == "Approved"){
            this.status = true;
          }else{
            this.status = false;

          }
          if(this.addForm.available == "Available"){
            this.available = true;
          }else{
            this.available = false;

          }
          let filter = {	
            "where":{
              "userId": this.id
              
            }
            
          };
          this.crudService.getAll(environment.api.address , filter).then((res:any)=>{
            if(res && res.length > 0){
              res.map((element)=>{
                if(element.addressType == "veterinarian"){
                  this.veterinarianAddress = element;
                }else   if(element.addressType == "supplyAccesories"){
                  this.supplierAddress = element;
                }else   if(element.addressType == "dogFood"){
                  this.dogFoodAddress = element;
                }
              })
            }

          })
        }
        })
      }
    }
  
    ngOnInit(): void {
    }

    checkStatus(){
      setTimeout(() => {
        if(this.status){
          this.addForm.approval = "Approved"
        }else{
          this.addForm.approval = "Pending"
  
        }
      }, 10);

    }


    checkAvail(){
      setTimeout(() => {
        if(this.available){
          this.addForm.available = "Available"
        }else{
          this.addForm.available = "Not Available"
  
        }
      }, 10);

    }
  
  
  changeVisibility()
     {
        if(this.Visibility=="visibility_off")
        {
          this.Visibility="visibility"
          this.type='text'
        }
        else if(this.Visibility=="visibility")
        {
          this.Visibility='visibility_off'
          this.type='password';
        }
     }
  AddSubAdmin() {
    if (this.addForm.veterinarian || this.addForm.supplyFood || this.addForm.supplyAccesories) {
      let allAddress = [];
      if (this.addForm.veterinarian) {
        allAddress.push(this.veterinarianAddress)
      }
      if (this.addForm.supplyFood) {
        allAddress.push(this.dogFoodAddress)
      }
      if (this.addForm.supplyAccesories) {
        allAddress.push(this.supplierAddress)
      }
      console.log(allAddress);
      console.log(this.addForm);

      if (this.id) {
        this.crudService.edit(this.addForm, environment.api.businessUser).then((res: any) => {
          allAddress.map((element) => {
            element.userId = this.id
          });
          // this.crudService.edit(allAddress , environment.api.address).then((res)=>{
          //   this.router.navigate(['/admin/businessUser/list']);
          //  this.snackbar.toastSuccess("Business user Details Updated  successfully.")

          // })
        })
      } else {
        this.userService.signup(this.addForm, environment.api.businessUser).then((res: any) => {
          allAddress.map((element) => {
            element.userId = res.userId;
            this.router.navigate(['/admin/businessUser/list'])
          });

        })
      }



    } else {
      this.snackbar.toastError("Please select at least one role")
    }
  }

}
