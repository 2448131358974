import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ROUTES } from './menu-items';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { usersService } from 'src/app/services/tikaUser/user.service';
 import { InventoryROUTES } from './menu-items';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  menuClass:any="";
  subMenuClass:any="";
  public sidebarnavItems: any[];
  path: any;
  userId: any;

  constructor(
    private r:Router,
    private activatedRoute:ActivatedRoute,
    public loader:LoaderService,
    private userService:usersService
    ){
      this.getuserLoginDetails();
      this.userId = JSON.parse(localStorage.getItem('userProfile'));
      console.log('7834578478347834',this.userId);
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
      let element:any = document.getElementsByClassName('example-sidenav');
      element.style['min-width'] = "200px";
    }
  }

  ngOnInit(){
    this.path = this.r.url;
    console.log(this.r.url);
    this.userId = JSON.parse(localStorage.getItem('userProfile'));
    if(this.userId.role == 'vendor'){
      this.sidebarnavItems =InventoryROUTES.filter(sidebarnavItem => sidebarnavItem);
    }else{
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
    
    
    this.sidebarnavItems.map((element)=>{
      if(element.path == this.path){
        this.menuClass = element.title;
      }
      if(element.submenu.length > 0){
        element.submenu.map((ele)=>{
          if(ele.path == this.path){
            this.menuClass = element.title;
            this.subMenuClass = ele.title;
          }
        })
      }
    })
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  navigateToPage(type,menu,submenu){
    console.log(type,menu,submenu);
    if(type == 'main'){
      this.menuClass = menu.title;
      this.subMenuClass="";
      this.r.navigate([menu.path])
    }else{
      this.menuClass = menu.title;
      this.subMenuClass = submenu.title;
      this.r.navigate([submenu.path])
    }
    
  }
  openSubMenu(menu){
    menu.expand = !menu.expand;
    if(this.isExpanded == false){
      this.isExpanded = !this.isExpanded;
      menu.expand = true;
    }
  }

  closeSearch(){
    this.loader.searchFlag = false;
  }

  getuserLoginDetails(){
    let data = localStorage.getItem('loginUser');
    if(data && JSON.parse(data)){
      this.loader.accessToken = JSON.parse(data).id;
      this.loader.userId = JSON.parse(data).userId;
      this.userService.checkprofile = JSON.parse(localStorage.getItem('userProfile'))
    }
  }
}
