<div class="row">
    <div class="col-md-4">
        <mat-card style="border-bottom: 12px solid #f39f26;">
            <mat-card-content>
                <p>{{reserveData}}</p>
                <p>Total Business User</p>

            </mat-card-content>
        </mat-card>
    </div>


    <!-- <div class = "col-md-4" >
            <mat-card style="border-bottom: 12px solid #3989a7;">
                <mat-card-content>
                    <p>{{reserve}}</p>
                    <p>total Appoinments</p>
    
                    </mat-card-content>
                </mat-card>
            </div> -->


    <div class="col-md-4">
        <mat-card style="border-bottom: 12px solid #b64a71;">
            <mat-card-content>
                <p>{{Data}}</p>
                <p>total Customers</p>

            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-md-4">
        <mat-card style="border-bottom: 12px solid #f39f26;">
            <mat-card-content>
                <p>{{clientCount}}</p>
                <p>Total Clients</p>

            </mat-card-content>
        </mat-card>
    </div>

</div>