import { AppSettings } from './../../../core/constant';
import { Router } from '@angular/router';
import { usersService } from '../../../services/tikaUser/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
addForm:any;
date=new Date()
Visibility:any='visibility_off'
type:string='password';icon:string="check_circle_outline";
icon1="check_circle"
  constructor(private ApiService:usersService,
    private router:Router) { 
    this.addForm=
    {
      emailVerified: true,
      realm: AppSettings.ROLE.USER,
      status: true,
      password:'',
      ConfirmPassword: "",
      email:'',
      createdAt:this.date
    }
  }

  ngOnInit(): void {
  }


changeVisibility()
   {
      if(this.Visibility=="visibility_off")
      {
        this.Visibility="visibility"
        this.type='text'
      }
      else if(this.Visibility=="visibility")
      {
        this.Visibility='visibility_off'
        this.type='password';
      }
   }
  AddSubAdmin()
  {
    this.ApiService.signup(this.addForm ,"user").then((res)=>
    {
      this.router.navigate(['/admin/subadmin'])
    })
  }
}
