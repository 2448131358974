import { Ng2TelInputModule } from 'ng2-tel-input';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from './shared/toast/toast.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogComponent, DialogDataDialog, DialogListDialog, DialogDetailDialog, DialogContentDialog, DialogSuccessDialog, DialogInputDataDialog } from './shared/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { TermsComponent } from './shared/terms/terms.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { AddInventoryComponent } from './admin/inventory/add-inventory/add-inventory.component';
import { ListInventoryComponent } from './admin/inventory/list-inventory/list-inventory.component';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { usersService } from './services/tikaUser/user.service';
import { HeaderComponent } from './admin/shared/header/header.component';
import { FooterComponent } from './admin/shared/footer/footer.component';
import { AdminModule } from './admin/admin.module';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    DialogComponent,
    DialogDataDialog,
    DialogListDialog,
    DialogDetailDialog,
    DialogContentDialog,
    DialogSuccessDialog,
    DialogInputDataDialog,
    PrivacyComponent,
    TermsComponent,

    
    
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    MatCardModule,
    AppRoutingModule,
    LottieModule.forRoot({ player: playerFactory }),
    BrowserAnimationsModule,
    MatSnackBarModule,
    FormsModule,
    MatDialogModule,
    CommonModule,
  AdminRoutingModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  AdminModule,
  MatIconModule,
  MatDatepickerModule,
  MatCardModule,
  MatNativeDateModule,
  MatSortModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatFormFieldModule,
  FormsModule,
  RxReactiveFormsModule,
  NgxIntlTelInputModule,
  ReactiveFormsModule,
    MatListModule,
    MatIconModule,
    HttpClientModule,
    MatFormFieldModule,
    NgbModule,
    Ng2TelInputModule,
    // MatRadioButtonModule
    MatButtonModule,
    MatRadioModule,
    CommonModule
    
  ],
  providers: [
    ToastComponent,
    DialogComponent,
    DialogDataDialog,
    DialogListDialog,
    DialogDetailDialog,
    DialogContentDialog,
    DialogSuccessDialog,
    DialogInputDataDialog,
    usersService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
