import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../../core/constant';
import { Router, ActivatedRoute } from '@angular/router';
import { usersService } from '../../../services/tikaUser/user.service';
import { environment } from 'src/environments/environment';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { element } from 'protractor';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent implements OnInit {
  addForm:any;
  date=new Date();
 
  hide = true;
 
  status : boolean = true;
  available : boolean = true;

  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";
  id:any;
  itemArray : any=[
    {id : 1,
      brandName : "",
    discount : "",
    customerDiscount:""
    }
  ];
  groups: any;
  item: any;
    constructor(private ApiService:usersService,
      private router:Router,
      private userService : usersService ,
      private crudService : CrudServiceService,
      private snackbar : ToastComponent,
      private activatedRoute : ActivatedRoute,
      ) { 
        this.id = this.activatedRoute.snapshot.paramMap.get("id");
      this.addForm=
      {name : "",
        approval: "Approved",
        password:'',
        mobile : "",
        email:'',
        createdAt:this.date,
        item:"",
        role : "vendor",
        customerDiscount:
        "",
        discount:"",
        brandName :"",
      },

   this. details()    
    }
    details(){
      if(this.id){
        this.crudService.getDedtail(this.id , environment.api.admin).then((res:any)=>{
          console.log('+++++++++++++++++++++',res)
        if(res && res._id){
          this.addForm = res;
          

          if(this.addForm.brands){
            this.itemArray = res.brands
          }
          else{
            // this.itemArray=""
            console.log(this.itemArray)
          }
           
            
            
         
          if(this.addForm.approval == "Approved"){
            this.status = true;
          }else{
            this.status = false;

          }
          if(this.addForm.available == "Available"){
            this.available = true;
          }else{
            this.available = false;

          }
          let filter = {	
            "where":{
              "userId": this.id
              
            }
            
          };
         
        }
        })
      }
    }
  
    ngOnInit(): void {
      this. details()
    }

    checkStatus(){
      setTimeout(() => {
        if(this.status){
          this.addForm.approval = "Approved"
        }else{
        //  console.log('334')
  
        }
      }, 10);

    }


    checkAvail(){
      setTimeout(() => {
        if(this.available){
          this.addForm.available = "Available"
        }else{
          // console.log('343434')
  
        }
      }, 10);

    }
  
 
  
  changeVisibility()
     {
        if(this.Visibility=="visibility_off")
        {
          this.Visibility="visibility"
          this.type='text'
        }
        else if(this.Visibility=="visibility")
        {
          this.Visibility='visibility_off'
          this.type='password';
        }
     }
    AddSubAdmin()
    {
    

      if(this.id){
        this.addForm.brands = this.itemArray 
        this.crudService.edit(this.addForm , environment.api.admin).then((res:any)=>{
          this.snackbar.toastSuccess(" Vendor Details Updated  successfully.")
          this.router.navigate(['/admin/vendor/list'])
        })
      }else{
        this.addForm.brands = this.itemArray
        this.userService.signup(this.addForm , environment.api.admin).then((res:any)=>{
          this.snackbar.toastSuccess(" Vendor Details Added  successfully.")
          this.router.navigate(['/admin/vendor/list'])
        
       
        })
      }




    }
    AddBrand(){
      // var currentElement = this.groups[index];  
      // this.groups.splice(index, 0, currentElement);
    }
    
    addItem(){
      let data =  {
        id :this.itemArray.length + 1,
        brandName : "",
        discount : "",
        customerDiscount:""
     }
     this.itemArray.push(data);
    }
  
}
