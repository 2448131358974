<div class="mat-card card">

    <div class="row">
        <div class="col-8">
            <h2 class="main-heading">
                Manage Enquiry

            </h2>
        </div>
    
    </div>
    <!-- <div>
      <input matInput (keyup)="applyFilterGrid($event.target.value)" placeholder="Filter">
    </div> -->
    <div class="matTableDiv">
        <table mat-table [dataSource]="reserveData" class="mat-elevation-z8" matSort>


            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Full Name </th>
                <td mat-cell *matCellDef="let element" id="Name">{{element?.fullName || ''}}
                    <!-- <ng-container *ngIf="element.name == undefined || element.name == '';else Name">NA</ng-container>
                    <ng-template #Name>{{element.name}}</ng-template> -->
                    <!-- <ng-container>{{element?.name || ''}}</ng-container> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let element" id="email">
                  {{ element?.email  }}
                    <!-- <ng-container *ngIf="element.createdAt == undefined || element.createdAt== '';else Name">NA</ng-container>
                    <ng-template #Name>{{ element.createdAt | date:'dd-MM-yy' }}</ng-template> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
              <td mat-cell *matCellDef="let element" id="message">
                {{ element?.message  }}
                  <!-- <ng-container *ngIf="element.createdAt == undefined || element.createdAt== '';else Name">NA</ng-container>
                  <ng-template #Name>{{ element.createdAt | date:'dd-MM-yy' }}</ng-template> -->
              </td>
          </ng-container>
         
            <!-- <ng-container matColumnDef="manname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager Name </th>
            <td mat-cell *matCellDef="let element" id="name">
              <ng-container *ngIf="element.managerId.name == undefined || element.managerId.name == '';else Name">NA</ng-container>
              <ng-template #Name>{{element.managerId.name}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="manemail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manager Email </th>
            <td mat-cell *matCellDef="let element" id="email">
              <ng-container *ngIf="element.managerId.email == undefined || element.managerId.email == '';else email">NA</ng-container>
              <ng-template #email>{{element.managerId.email}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="manmobile">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager Mobile </th>
            <td mat-cell *matCellDef="let element" id="mobile">
              <ng-container *ngIf="element.managerId.mobile == undefined || element.managerId.mobile == '';else mobile">NA</ng-container>
              <ng-template #mobile>{{element.managerId.mobile}}</ng-template>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="branch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Branch </th>
            <td mat-cell *matCellDef="let element">
              <span>{{element.branch}}</span>
            </td>
          </ng-container>
    
     -->

          
            <tr mat-header-row *matHeaderRowDef="displayedColumns">

            </tr>
            <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">

            </tr>

        </table>
        <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
        </mat-paginator>
    </div>

</div>