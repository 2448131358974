import { Component, OnInit } from '@angular/core';
import {  Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { usersService } from '../../../services/tikaUser/user.service';
import { MatPaginator } from '@angular/material/paginator';
import {PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
@Component({
  selector: 'app-list-order',
  templateUrl: './list-order.component.html',
  styleUrls: ['./list-order.component.scss']
})
export class ListOrderComponent implements OnInit {

  displayedColumns: string[] = ['name','id','address','assignPartner','status','price','action' ];
  dataSource :any = new MatTableDataSource;
  @Output() options:any = "data";
  maxperpage : number = 100;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  reserveData:any;
  userId: any;
  addForm:any;
  deliveryGuy: any;
constructor(
  private apiService:usersService,
  private route:Router,
  private crudService : CrudServiceService,
  private dialog: DialogComponent,
  private toast : ToastComponent,
  private matDialog : MatDialog
  ) {
    this.addForm=
    {
      deliveryGuy:"",
    }
    this.userId = JSON.parse(localStorage.getItem('userProfile'));
}

   ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort=this.sort;
    this.getAllSubadmin()

      this.crudService.getAll(environment.api.businessUser , {role : "delivery",vendorId : this.userId._id}).then((res:any)=>
      {
        console.log(res)
        this.deliveryGuy = res;
        console.log('22222222222222222',this.deliveryGuy.name)
       
      })


  
    
  }

   getPageSizeOptions(): number[]{
    if(this.paginator.length>this.maxperpage)
    return [5, 10,50,this.maxperpage, this.dataSource.data.length];
    else
    return [5,10,50, this.maxperpage];
  }

  addSurcharge(value , orderId,name,mobile,images){
  // this.crudService.editOrder({"deliveryId":value ,_id:orderId,status:"Delivery guy assigned"} , environment.api.order).then((res)=>{
  //   this.toast.toastSuccess("Delivery Guy updated successfully.");
  //   // console.log('1111111111111111111111')
   
  //   })

    this.crudService.updateStatus({"deliveryId":value ,_id:orderId,status:"Delivery guy assigned"}).then((res)=>{
      this.toast.toastSuccess("Delivery Guy updated successfully.");
      // console.log('1111111111111111111111')
     
      })
    
}



  getAllSubadmin()
  {
       this.crudService.getAll(environment.api.order ,  {vendorId : this.userId._id}).then((res:any)=>
      {
      console.log(res)
      this.reserveData = res;
      console.log('34444444444444',res)
     console.log('----------------3222222222222222222222222222222222222222-----',this.reserveData)
      this.reserveData.map((elem)=>{
        if(!elem.deliveryId){
          elem.deliveryId = ""

        }
        // this.deliveryGuy = elem.deliveryId

      })
  
      this.dataSource.data=this.reserveData;
      console.log('this.datasource',this.dataSource.data)
    })
  }



deleteUser(id)
{
  this.dialog.openDialog("Delete Order ","Are you sure to delete the Order?").then((isOkay)=>{
    if(isOkay){
  this.crudService.delete(id , environment.api.order).then((res)=>{
    console.log('deley')
    this.getAllSubadmin();
  
  })
    }
  })

}

  ngOnInit(): void {
      }

      

      
    }
