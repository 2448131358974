import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  dropdown:boolean = false;
  dropdownTimer:boolean = false;
  searchFlag:boolean = false;
  loading: boolean = false;
  accessToken:any;
  userId:any;
  constructor() { }

  showLoading() {
   this.accessToken =  JSON.parse(localStorage.getItem("userProfile")) ? JSON.parse(localStorage.getItem("userProfile")).token : null;
    this.loading=true;
  }

  hideLoading() {
    this.loading=false;
  }     
}
