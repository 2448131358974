import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { usersService } from 'src/app/services/tikaUser/user.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {

  addForm : any;
  id : any;
  Visibility:any='visibility_off'
  type:string='password';icon:string="check_circle_outline";
  icon1="check_circle";
  adoptionStatus : boolean = true;

  constructor(private crudService : CrudServiceService,
    private activatedRoute : ActivatedRoute,
    private userService : usersService , 
    private toast : ToastComponent,
    private router : Router) { 

    this.addForm = {
      name : "",
      email : "",
      mobile:"",
      password : "",
      houseNo : "",
      street : "",
      landmark : "",
      city : "",
      adoptionStatus:false,
      registrationType : "admin"
    }

    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    if(this.id){
      this.crudService.getDedtail(this.id , environment.api.customer).then((res:any)=>{
        this.addForm = res;
     
      })
    }
  }

  changeVisibility()
  {
     if(this.Visibility=="visibility_off")
     {
       this.Visibility="visibility"
       this.type='text'
     }
     else if(this.Visibility=="visibility")
     {
       this.Visibility='visibility_off'
       this.type='password';
     }
  }

  AddCustomer(){
    console.log(this.addForm)
    if(this.addForm.name && this.addForm.email){
      if(this.id){
        this.crudService.edit(this.addForm , environment.api.customer).then((res)=>{
          this.toast.toastSuccess("Customer details updated successfully.");
          this.router.navigate(['/admin/customer/list']);
          })
      }else{
        this.userService.signup(this.addForm , environment.api.customer).then((res)=>{
        this.toast.toastSuccess("Customer created successfully.");
        this.router.navigate(['/admin/customer/list']);

        })
      }
    }
  }

  ngOnInit(): void {
  }
  checkAvail(){
    setTimeout(() => {
      if(this.adoptionStatus){
        this.addForm.adoptionStatus = "Adoption"
      }else{
        this.addForm.adoptionStatus = "Not Adoption"

      }
    }, 10);

  }
}
