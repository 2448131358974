import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthloginService implements CanActivate {
  userId: any;

  constructor(private router:Router) {
    this.userId = JSON.parse(localStorage.getItem('userProfile'));
   }
  
  canActivate():any{

    if(localStorage.getItem("userProfile")){
      if(this.userId.role == 'vendor'){
        this.router.navigate(['/vendor/food/list']);
        return false;
      }
      else{
        this.router.navigate(['/admin/dashboard']);
        return false;
      }
      

  }
  else{
    return true;
  }
}
}
