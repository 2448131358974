import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { environment } from 'src/environments/environment';
import { usersService } from 'src/app/services/tikaUser/user.service';

@Component({
  selector: 'app-add-breed',
  templateUrl: './add-breed.component.html',
  styleUrls: ['./add-breed.component.scss']
})
export class AddBreedComponent implements OnInit {

  addForm : any;
  id : any;


  constructor(private crudService : CrudServiceService,
    private activatedRoute : ActivatedRoute,
    private toast : ToastComponent,
    private router : Router,
    public userService : usersService) {
      
      this.addForm = {
        name : "",
        status : false,
        adaptability :[],
        nativity : "",
        childFriendly :false,
        apartmentFriendly : false
      }
      this.id = this.activatedRoute.snapshot.paramMap.get("id");
      if(this.id){
        this.crudService.getDedtail(this.id , environment.api.breed).then((res)=>{
          this.addForm = res;
        })
      }
     }

     AddBreed(){
       if(this.addForm.name ){
         if(this.id){
           this.crudService.edit(this.addForm , environment.api.breed).then((res)=>{
             this.router.navigate(['/admin/breed/list']);
             this.toast.toastSuccess("Breed details updated successfully")
           })
         }else{
          this.crudService.add(this.addForm , environment.api.breed).then((res)=>{
            this.router.navigate(['/admin/breed/list']);
            this.toast.toastSuccess("Breed added successfully")
          })
         }
       }
     }

  ngOnInit(): void {
  }

}
