
            
          <mat-card>
              <form #Form="ngForm">
            <div class="row ">
                <div class="co-12 heading">
                     Forgot Password    
                 </div>    
                 <div class="col-12" class="field">
                     <input class="inputbox" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="email" [(ngModel)]="addForm.email" #email="ngModel"  required>
                     <span class="material-icons">
                         check_circle_outline
                         </span>
                 </div>
                 <div class="col-12">
                 <button mat-stroked-button class="button1" [disabled]="Form.invalid" (click)="submit()" >
                    <div *ngIf="!addForm.email && !email.dirty" >Enter Email
                    </div>  <div
                    [hidden]="email.valid || email.pristine"
                    class="erro1"
                  >
                    <div *ngIf="email.errors?.required">Email is required</div>
                    <div *ngIf="email.errors?.email">
                      Email must be a valid email address
                    </div>
          
                  </div> 
<div *ngIf="Form.valid">Submit</div>
                 </button>
                 </div>
                 <div class="col-12">
                     <p class="accunt">Already have and account ?<span (click)="login()"> Login</span></p>
                 </div>    
             </div></form>
           </mat-card>
       
     