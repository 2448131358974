




<div  class="mat-card card">


    <div class="matTableDiv">
  <!-- <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
  

      <ng-container matColumnDef="doctorName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Doctor name </th>
        <td mat-cell *matCellDef="let element" id="doctorName">
          <ng-container *ngIf="element.doctorName == undefined || element.doctorName == '';else doctorName">NA</ng-container>
                      <ng-template #doctorName>{{element.doctorName}}</ng-template>
   </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer </th>
        <td mat-cell *matCellDef="let element" id="customerName">
          <ng-container *ngIf="element.customerName == undefined || element.customerName == '';else customerName">NA</ng-container>
                      <ng-template #customerName>{{element.customerName}}</ng-template>
   </td>
      </ng-container>
  
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
        <td mat-cell *matCellDef="let element" id="amount">
          <ng-container *ngIf="element.amount == undefined || element.amount == '';else amount">NA</ng-container>
                      <ng-template #amount>{{element.amount}}</ng-template>
   </td>
      </ng-container>
      <ng-container matColumnDef="for">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid for </th>
        <td mat-cell *matCellDef="let element" id="for">
          <ng-container *ngIf="element.for == undefined || element.for == '';else for">NA</ng-container>
                      <ng-template #for>{{element.for}}</ng-template>
   </td>
      </ng-container>




      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Did customer pay </th>
        <td mat-cell *matCellDef="let element" id="date">
          <ng-container *ngIf="element.date == undefined || element.date == '';else date">NA</ng-container>
                      <ng-template #date>{{element.date  | date:'mediumDate'}}</ng-template>
   </td>
      </ng-container>

      <ng-container matColumnDef="payByAdmin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Did we pay  to doctor </th>
        <td mat-cell *matCellDef="let element" id="payByAdmin">
          <ng-container *ngIf="element.payByAdmin == undefined || element.payByAdmin == '';else payByAdmin">Not yet</ng-container>
                      <ng-template #payByAdmin> Yes, {{element.payByAdmin  | date:'mediumDate'  }}</ng-template>
   </td>
      </ng-container>



      <ng-container  matColumnDef="paid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let element" id="paid">
          <button mat-button *ngIf="!element.payByAdmin" class="submit" (click)="editPayment(element._id)">Paid</button>
   </td>
      </ng-container>

 
      <tr mat-header-row *matHeaderRowDef="displayedColumns">
  
      </tr>
      <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">
  
      </tr>
  
    </table> -->



<table>
  <thead>
    <tr  style="    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;">
      <th class="dataName">Doctor name</th>
      <th class="dataName">Customer</th>
      <th class="dataName">Amount</th>
      <th class="dataName">Paid for</th>
      <th class="dataName">Did customer pay</th>
      <th class="dataName">Did we pay  to doctor</th>
      <th class="dataName"></th>
    </tr>

  </thead>
  <tbody>
<div style="border-top: 1px solid #ddd;border-bottom: 1px solid #ddd;
" *ngFor="let a of reserveDatas ; let i  = index">

<tr style="display: block;text-align: center;">   <div  style="    padding: 14px 25px;
  background: bisque;
  font-size: 16px;
"  *ngIf = "i!=0 &&!(reserveDatas[i].day == reserveDatas[i-1].day && 
  reserveDatas[i].month == reserveDatas[i-1].month && reserveDatas[i].year == reserveDatas[i-1].year  )">
  {{reserveDatas[i].date | date}}</div></tr>


  <tr style="    display: flex;
  justify-content: space-between;"><td class="dataName" class="dataName"> <span *ngIf="a.doctorName != undefined">{{a.doctorName}}  </span>
    <span *ngIf="a.doctorName == undefined" >NA  </span></td>  



    <td class="dataName"> <span *ngIf="a.customerName != undefined">{{a.customerName}}  </span>
      <span *ngIf="a.customerName == undefined" >NA  </span></td>  
     
      <td class="dataName"> <span *ngIf="a.amount != undefined">{{a.amount}}  </span>
        <span *ngIf="a.amount == undefined" >NA  </span></td>  

        <td class="dataName"> {{a.for}}  </td>
      <td class="dataName">Yes, {{a.date  | date:'mediumDate'}}   </td>
      <td class="dataName"> <span *ngIf="a.payByAdmin != undefined">{{a.payByAdmin  | date:'mediumDate'}}  </span>
        <span *ngIf="a.payByAdmin == undefined" >Not yet  </span></td>  
      <td class="dataName">  <button mat-button *ngIf="!a.payByAdmin" class="submit" (click)="editPayment(a._id)">Paid</button>  </td> 
</tr>
</div>


<!-- 
    <tr >
    

  
    </tr> -->

  </tbody>
  
</table>

    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = $event">
  </mat-paginator>
   </div>
  </div>
 