<div class="container">
  <div class="row d-flex justify-content-center" >
    <div class="col-12">
        <mat-card>
            <form #f="ngForm">
        <div class="row ">
                <div class="col-12 ">
                    <h2 class="heading" >Add Location</h2>  

                </div>  
                <div class="inputarea">
                <div class="col-12">
                    <input class="inputbox" name="name" [(ngModel)]="addForm.city"  value="" #city="ngModel" placeholder="City" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required >
                    <span class="material-icons" >
                      face
                      </span>
                    <div
[hidden]="city.valid || city.pristine"
class="erro1"
>City is Required
</div>
  
                  </div>



             


                <div class="col-12">

            
        
                <div class="col-12">
              </div>
         
                    <button mat-button [disabled]="f.invalid" class="submit" (click)="AddCustomer()">submit</button>
                    <mat-slide-toggle class="toogle"
                    [(ngModel)]="addForm.status"
                    name="status"
                    #status="ngModel"
                    >status</mat-slide-toggle
                  >
                </div>    
                
            </div> 
                  
            </div> </form>
        </mat-card>
      </div>    
</div>

</div>   
