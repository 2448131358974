import { AppSettings } from './../../core/constant';
import { Router } from '@angular/router';
import { usersService } from '../../services/tikaUser/user.service';
import { Component, OnInit } from '@angular/core';
import { FileUpload } from 'src/app/shared/classes/file-upload';
import { FileUploadService } from 'src/app/services/fileUpload/file-upload.service';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  addForm:any;
  userId:any;
  selectedFiles: FileList;
  currentUpload: FileUpload;

    constructor(
      private ApiService:usersService,
      public FileUploadService:FileUploadService,
      private router:Router,
      private crudService : CrudServiceService) { 
        this.addForm = JSON.parse(localStorage.getItem("userProfile"));
        delete this.addForm.password;
        
        this.userId = JSON.parse(localStorage.getItem("userProfile"))._id;
      }
  
    ngOnInit(): void {
    }

    onFileInput (event) {
      this.selectedFiles = event.target.files;
    let file = this.selectedFiles.item(0)
    this.currentUpload = new FileUpload(file);
    this.deleteImage();
    
    }
    
    deleteImage(){
      this.FileUploadService.deleteUpload(this.addForm.profileImage,'/AdminProfileImage/'+this.userId).then((res)=>{
        console.log(res);
        this.uploadImage();
      }).catch((err)=>{
        console.log(err);
        this.uploadImage();
      })
    }

    removeImage(){
      this.FileUploadService.deleteUpload(this.addForm.profileImage,'/AdminProfileImage/'+this.userId).then((res)=>{
        console.log(res);
        this.addForm.profileImage = {};
        this.editSubAdmin();
      }).catch((err)=>{
        console.log(err);
        this.addForm.profileImage = {};
        this.editSubAdmin();
      })
    }
    
    uploadImage(){
      this.FileUploadService.pushUpload(this.currentUpload,'/AdminProfileImage/'+this.userId).then((res)=>{
        console.log(res);
        this.addForm.profileImage=res;
      this.editSubAdmin();
      });
    }

    editSubAdmin(){
      this.crudService.edit(this.addForm,environment.api.admin).then((res)=>{
        this.ApiService.getUserDetails(this.userId).then((res)=>{
          this.addForm = res;
        })
      })
    }
  
    AddSubAdmin()
    {
      this.crudService.edit(this.addForm,environment.api.admin).then((res : any)=>{
        if(res && res.message == "Updated Successfully"){
          localStorage.setItem("userProfile" , JSON.stringify(res.data))
          this.router.navigate(['/admin/dashboard'])
        }
      })
    }
}
