<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading">Add Breed</h2>  
                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="name" [(ngModel)]="addForm.name"   #name="ngModel" 
                      placeholder="Breed Name" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
                      <div
  [hidden]="name.valid || name.pristine"
  class="erro1"
>Name is Required
</div>
    
                    </div>















                    <div class="col-xs-12 col-sm-12 col-md-12 form-group waylf">
                      <p id="demos" >Select Adaptability</p>
                      <div class="row" style="margin-top:2%;margin-left:0%;">
                        <div class="col-sm-6 col-md-4 first_sboxcon">
                          <label class="slect_box"><input  class="checkbox_enquiry" type="checkbox" name="childFriendly" [(ngModel)]="addForm.childFriendly"   #childFriendly="ngModel"><span>Child Friendly</span></label>
                          <!-- <label class="slect_box"><input   class="checkbox_enquiry" type="checkbox"  value="Hire Dedicated Resources"  ><span>Apartment Friendly</span></label> -->
                          
                        </div>
                        <div class="col-sm-6 col-md-4 first_sboxcon">
                          <!-- <label class="slect_box"><input  class="checkbox_enquiry" type="checkbox"  value="Mobile App Development" checked ><span>Child Friendly</span></label> -->
                          <label class="slect_box"><input   class="checkbox_enquiry" type="checkbox" name="apartmentFriendly" [(ngModel)]="addForm.apartmentFriendly"   #apartmentFriendly="ngModel" ><span>Apartment Friendly</span></label>
                          
                        </div>
                        <!-- <div class="col-sm-6 col-md-4">
                          <label class="slect_box"><input  class="checkbox_enquiry" type="checkbox"  value="Application Service" ><span>Application Service</span></label>
                          <label class="slect_box"><input  class="checkbox_enquiry" type="checkbox"  value="Progressive Web App" ><span>Progressive Web App</span></label>
                        </div>
                        <div class="col-sm-6 col-md-4">                       
                          <label class="slect_box"><input class="checkbox_enquiry" type="checkbox"  value="Cloud Solution" ><span>Cloud Solution</span></label>
                        </div> -->
                
                      </div>
              
              
                    </div>






                    <div class="col-12">
                      <select class="inputbox" name="nativity" [(ngModel)]="addForm.nativity"   #nativity="ngModel" [ngClass]="{ 'is-invalid': f.submitted && nativity.invalid }" required >
                        <option value= "" selected hidden>Nativity</option>
                     
                        <option *ngFor = "let country of userService.allCountries" value="{{country?.name}}">{{country?.name}}</option>
                      </select>

                      <div
  [hidden]="nativity.valid || nativity.pristine"
  class="erro1"
>Nativity is Required
</div>
    
                    </div>








                  <div class="col-12">

           
                      <button mat-button [disabled]="f.invalid" class="submit" (click)="AddBreed()">submit</button>
                      <mat-slide-toggle class="toogle"
                      [(ngModel)]="addForm.status"
                      name="status"
                      #status="ngModel"
                      >status</mat-slide-toggle
                    >
                  </div>    
                  
              </div> 
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 