<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading">Add Vaccine</h2>  
                  </div>  
                  <div class="inputarea">
                  <div class="col-12">
                      <input class="inputbox" name="range" [(ngModel)]="addForm.range"   #range="ngModel" placeholder="Range" [ngClass]="{ 'is-invalid': f.submitted && range.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
                      <div
  [hidden]="range.valid || range.pristine"
  class="erro1"
>Range is Required
</div>
    
                    </div>
                    <p id="demos" >Vaccines</p>

                    <div class="col-12" *ngFor = "let vaccine of addForm.vaccineList;let i = index">
                        <input class="inputbox" name="{{i}}" [(ngModel)]="vaccine.name"   #{{i}}="ngModel" placeholder="Vaccine Name" [ngClass]="{ 'is-invalid': f.submitted && i.invalid }" required >
                        <span class="material-icons removeIcon" (click) = "removeIcon(i)">
                          remove
                          </span>
                        <!-- <div
    [hidden]="name.valid || name.pristine"
    class="erro1"
  >Name is Required
  </div> -->
  <div class = "col-12">
  <input type="radio" id="male_{{i}}" name="type_{{i}}" value="Recomanded" [(ngModel)] = "vaccine.type" style="margin-right: 6px;">
  <label for="Recomanded">Recomanded</label>
  <input type="radio" id="female_{{i}}" name="type_{{i}}" value="Optional" [(ngModel)] = "vaccine.type" style="margin-left: 34px;
  margin-right: 6px;">
  <label for="Optional">Optional</label>
  </div>
      
                      </div >

                      <div class = "col-md-12">
                      <button mat-button [disabled]="!addForm.vaccineList[addForm.vaccineList.length-1].name" class="addButton" (click)="AddVaccine()">Add</button>

                      </div>




                  <div class="col-12">

           
                      <button mat-button [disabled]="f.invalid" class="submit" (click)="AddVaccineGroup()">submit</button>
                    
                    
                  </div>    
                  
              </div> 
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 