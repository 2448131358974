<!-- <div class = "row" *ngFor="let res of reserveData">
    <div class = "col-md-4">          
        <mat-card style="border-bottom: 12px solid #f39f26;">
            <mat-card-content>
                <p>{{res.name}}</p>
                <p>total Vets</p>

                </mat-card-content>
            </mat-card>
        </div>


        <div class = "col-md-4" >
            <mat-card style="border-bottom: 12px solid #3989a7;">
                <mat-card-content>
                    <p>{{res.email}}</p>
                    <p>total Appoinments</p>
    
                    </mat-card-content>
                </mat-card>
            </div>


            <div class = "col-md-4">
                <mat-card style="border-bottom: 12px solid #b64a71;">
                    <mat-card-content>
                        <p>{{res.mobile}}</p>
                        <p>total Customers</p>
        
                        </mat-card-content>
                    </mat-card>
                </div>


    </div> -->


    <!-- ///////////////////////////////////////////////////////////////////////////////////////////// -->
    <div  class="mat-card card"  >

  
  
        <div class="row">
          <div class="col-8">
              <h2 class="main-heading">
                Manage Queries
              </h2> 
          </div>  
         
        </div> 
        <div class="matTableDiv">
      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" >
      
    
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
            <td mat-cell *matCellDef="let element" id="name">
              <ng-container *ngIf="element.name == undefined || element.name == '';else Name">NA</ng-container>
                          <ng-template #Name>{{element.name}}</ng-template>
       </td>
          </ng-container>
      
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header  > Email </th>
            <td mat-cell *matCellDef="let element" id="email">
              <ng-container *ngIf="element.email == undefined || element.email == '';else email">NA</ng-container>
                          <ng-template #email>{{element.email}}</ng-template>
       </td>
          </ng-container>
    
          <ng-container matColumnDef="mobile" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Mobile </th>
            <td  mat-cell *matCellDef="let element"  id="mobile" 
            >
            <ng-container *ngIf="element.mobile == undefined || element.mobile == '';else user">NA</ng-container>
            <ng-template #user>{{element.mobile}}</ng-template>
            </td> 
          </ng-container>
          
        
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header  > Message </th>
            <td  mat-cell *matCellDef="let element"  id="message" 
            >
            <ng-container *ngIf="element.message == undefined || element.message == '';else message">NA</ng-container>
            <ng-template #message>{{element.message}}</ng-template>
            </td> 
          </ng-container>
    
    
        <ng-container  matColumnDef="action">
           <th mat-header-cell *matHeaderCellDef > Action </th>
          <td mat-cell *matCellDef="let element">
            <!-- <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span> -->
              <span class="material-icons editDelete" (click)="deleteUser(element._id)" title="Delete">delete</span>
      </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns">
      
          </tr>
          <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">
      
          </tr>
      
        </table>
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event">
      </mat-paginator>
       </div>
      </div>
        
          