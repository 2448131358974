import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { environment } from '../../../environments/environment';
import { FileUpload } from 'src/app/shared/classes/file-upload';
import { LoaderService } from '../loader/loader.service';

firebase.initializeApp(environment.firebase); 

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {


  loadingFlag:boolean = false;
  uploadPercentage:any = '';
  constructor(public loadingService : LoaderService,) { }

  private basePath:string = '/';

  // pushUpload(upload: FileUpload, subfolderPath : string ) {
  //   let storageRef = firebase.storage().ref();
  //   let folderPath = this.basePath + subfolderPath;
  //   let date = new Date();
  //   let name = ''; 
  //   name = name + date.getFullYear()+(date.getMonth() + 1)+date.getDate()+date.getHours()+date.getMinutes()+date.getSeconds()+'-'+date.getMilliseconds()+ upload.file.name;
  //   upload.name = name;
  //   let childStorageRef = storageRef.child(`${folderPath}/${upload.name}`);
  //   let uploadTask = childStorageRef.put(upload.file);

  //   return new Promise((resolve, reject) => {
  //     this.loadingFlag = true;

  //   uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
  //     (snapshot) =>  {
  //       this.uploadPercentage = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(0); 
  //       // upload in progress
  //       upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //     },
  //     (error) => {
  //       // upload failed
  //       this.loadingFlag = false;
  //       console.log(error)
  //       reject(error);
  //     },
  //     () => {
  //       // upload success
  //       // upload.url = uploadTask.snapshot.downloadURL;
  //       // upload.name = upload.file.name;
  //       let snapshotData = JSON.parse(JSON.stringify(uploadTask.snapshot.metadata));
  //       childStorageRef.getDownloadURL().then((path)=>{
  //       this.loadingFlag = false;
  //       snapshotData['url'] = path;
  //         resolve(snapshotData);
  //       });
        
  //     }
  //   );
  // });
  // }

  pushUpload(upload: FileUpload, subfolderPath ) {
    this.loadingService.showLoading();
    console.log(subfolderPath)
    let date = new Date();
    let name = ''; 
    console.log(upload.file.name);
    name = name + date.getFullYear()+(date.getMonth() + 1)+date.getDate()+date.getHours()+date.getMinutes()+date.getSeconds()+date.getMilliseconds() + '_'+ upload.file.name;
  console.log(name);
  upload.name = name;
  console.log(upload.name);
    let storageRef = firebase.storage().ref();
    let folderPath = this.basePath + subfolderPath;
    let childStorageRef = storageRef.child(`${folderPath}/${upload.name}`);
    let uploadTask = childStorageRef.put(upload.file);

    return new Promise((resolve, reject) => {
      this.loadingFlag = true;
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>  {
        // upload in progress
        this.uploadPercentage = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(0); 
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      (error) => {
        // upload failed
        this.loadingService.hideLoading();
        console.log(error)
        this.loadingFlag = false;
        reject(error);
      },
      () => {
        // upload success
        // upload.url = uploadTask.snapshot.downloadURL;
        // upload.name = upload.file.name;
        let snapshotData = JSON.parse(JSON.stringify(uploadTask.snapshot.metadata));
        childStorageRef.getDownloadURL().then((path)=>{
          snapshotData['url'] = path;
        this.loadingFlag = false;

          resolve(snapshotData);
        this.loadingService.hideLoading();

        });
        
      }
    );
  });
  }
  deleteUpload(upload: FileUpload, subfolderPath : string) {
      return this.deleteFileStorage(upload.name,subfolderPath);
  }



  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name:string, subfolderPath : string) {
    let folderPath = this.basePath + subfolderPath;

    return new Promise((resolve, reject) => {
      let storageRef = firebase.storage().ref();
      storageRef.child(`${folderPath}/${name}`).delete().then((res)=>{
        resolve(true);
        
      },
      (err) => {
        reject(err);
      }
      )
    });
  }

}
