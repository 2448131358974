import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { usersService } from 'src/app/services/tikaUser/user.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  addForm:any;
  userData : any;
  constructor(
    private r:Router,
    private userSerivce:usersService,
    private toast:ToastComponent
  ) {
    this.addForm = {
      "current":"",
      "new":"",
      "confirm":""
    };
    this.userData = JSON.parse(localStorage.getItem("userProfile"));
   }

  ngOnInit(): void {
  }

  
  changePassword(){
    let dat = JSON.parse(localStorage.getItem('loginUser'))
     if(this.addForm.current && this.addForm.new){
       if(this.addForm.new == this.addForm.confirm){
         if(this.addForm.current !== this.addForm.new){
           let passworddata =
           {"oldPass":this.addForm.current,
           "newPass":this.addForm.new,
          "id" : this.userData._id};
             this.userSerivce.updatePassword(passworddata , environment.api.admin).then((res)=>{
               console.log(res);
               if(res !== false){
                 this.r.navigate(['/admin/dashboard']);
               }
             })  
         }else{
         this.toast.toastError("Password can't be same as old password");
         }
       }else{
         this.toast.toastError("Password does't Match");
         console.log("password doesn't match");
       }
     }else{
       this.toast.toastError("Please enter a Password");
     }
   }

}
