<div class="container">
    <div class="row d-flex justify-content-center" >
      <div class="col-12">
          <mat-card>
              <form #f="ngForm">
          <div class="row ">
                  <div class="col-12 ">
                      <h2 class="heading">Profile</h2>  
                  </div>  
                  <div class="inputarea">
                  <div class="col-12" *ngIf="(addForm.profileImage && addForm.profileImage.url)">
                      <img class="profileImage"  src="{{addForm.profileImage.url}}">
                    </div>
                    <!-- <div class="col-12">
                        <button mat-button class="submit" *ngIf="!(addForm.profileImage && addForm.profileImage.url)" (click)="fileInput.click()">Add Image</button>
                        <button mat-button class="submit" *ngIf="(addForm.profileImage && addForm.profileImage.url)" (click)="fileInput.click()"> Change </button>
                        <button mat-button class="submit" *ngIf="(addForm.profileImage && addForm.profileImage.url)" (click)="removeImage()"> Remove </button>
    
                        <input #fileInput id="fileInput" type="file" (change)="onFileInput($event)" style="display:none;"/>
                    </div> -->


                  <div class="col-12">
                      <input class="inputbox" name="username" [(ngModel)]="addForm.name"  value="" #username="ngModel" placeholder="FullName" [ngClass]="{ 'is-invalid': f.submitted && username.invalid }" required >
                      <span class="material-icons" >
                        face
                        </span>
            
    
                    </div>
              
                  <div class="col-12">
                    <input class="inputbox" placeholder="Email" name="email" [(ngModel)]="addForm.email" #email="ngModel" required email >
                    <span class="material-icons" >
                        email
                        </span>
                   
            </div> 
                  <!-- <div class="col-12">
                      <input class="inputbox"  placeholder="Mobile No" name="mobile"  [(ngModel)]="addForm.mobileNo" #mobile="ngModel" 
                      minlength="8" required >
                      <span class="material-icons" >
                        cellphone
                        </span>
                    </div> -->
          
                  <div class="col-12">
                      <button mat-button class="submit" (click)="AddSubAdmin()">submit</button>
                </div>
           
                  </div>    
                    
              </div> </form>
          </mat-card>
        </div>    
  </div>
  
 </div>   
 