




<div  class="mat-card card">


    
  
  
    <div class="row">
      <div class="col-8">
          <h2 class="main-heading">
            Manage Consulting
           
          </h2> 
      </div>  
     
    </div> 
    <div class="matTableDiv">
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
  

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  User Name </th>
        <td mat-cell *matCellDef="let element" id="userName">
          <ng-container *ngIf="element.userName == undefined || element.userName == '';else userName">NA</ng-container>
                      <ng-template #userName>{{element.userName}}</ng-template>
   </td>
      </ng-container>
  
  


      
   

      





    <ng-container  matColumnDef="action">
       <th mat-header-cell *matHeaderCellDef > Action </th>
      <td mat-cell *matCellDef="let element">
        <!-- <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span> -->
          <span class="material-icons editDelete" (click)="deleteUser(element)" title="Delete">delete</span>
  </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns">
  
      </tr>
      <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">
  
      </tr>
  
    </table>
    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = $event">
  </mat-paginator>
   </div>
  </div>
