<div  class="mat-card card">

  
  
    <div class="row">
      <div class="col-8">
          <h2 class="main-heading">
            Manage Business User
          </h2> 
      </div>  
      <div class="col-4">
        <button mat-button class="submit" (click)="addUser()">Add Business User</button>
      </div>
    </div> 
    <div class="matTableDiv">
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
  

      <ng-container matColumnDef="dogName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dog Name </th>
        <td mat-cell *matCellDef="let element" id="dogName">
          <ng-container *ngIf="element.dogName == undefined || element.dogName == '';else Name">NA</ng-container>
                      <ng-template #Name>{{element.dogName}}</ng-template>
   </td>
      </ng-container>
  
      <ng-container matColumnDef="ownerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner Name </th>
        <td mat-cell *matCellDef="let element" id="ownerName">
          <ng-container *ngIf="element.ownerName == undefined || element.ownerName == '';else email">NA</ng-container>
                      <ng-template #email>{{element.ownerName}}</ng-template>
   </td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
        <td  mat-cell *matCellDef="let element"  id="userName" 
        >
        <ng-container *ngIf="element.userName == undefined || element.userName == '';else user">NA</ng-container>
        <ng-template #user>{{element.userName}}</ng-template>
        </td> 
      </ng-container>
      
      <ng-container matColumnDef="Usermobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
        <td  mat-cell *matCellDef="let element"  id="Usermobile" 
        >
        <ng-container *ngIf="element.Usermobile == undefined || element.Usermobile == '';else usermobile">NA</ng-container>
        <ng-template #usermobile>{{element.Usermobile}}</ng-template>
        </td> 
      </ng-container>

      <ng-container matColumnDef="supplyFood">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sell Food </th>
        <td  mat-cell *matCellDef="let element"  id="name" 
        >
          <span >
            {{ element.supplyFood ? 'Active' : 'Deactivate' }}
          </span>
        </td> 
      </ng-container>


      <ng-container matColumnDef="supplyAccesories">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sell Accessories </th>
        <td  mat-cell *matCellDef="let element"  id="supplyAccesories" 
        >
          <span >
            {{ element.supplyAccesories ? 'Active' : 'Deactivate' }}
          </span>
        </td> 
      </ng-container>

      <ng-container matColumnDef="approval">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Approval </th>
        <td  mat-cell *matCellDef="let element"  id="name" 
        >
        <span [ngClass]="element.approval == 'Approved' ? 'StatusGreen' :'StatusRed' ">
          {{ element.approval  }}
        </span>
        </td> 
      </ng-container>


    <ng-container  matColumnDef="action">
       <th mat-header-cell *matHeaderCellDef > Action </th>
      <td mat-cell *matCellDef="let element">
        <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span>
          <span class="material-icons editDelete" (click)="deleteUser(element._id)" title="Delete">delete</span>
  </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns">
  
      </tr>
      <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">
  
      </tr>
  
    </table>
    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = $event">
  </mat-paginator>
   </div>
  </div>
    <!-- <div class="footer">
      <div class="pagination">
          <a class="active" href="#">&laquo;</a>
          <a class="active" href="#">1</a>
          <a href="#">2</a>
          <a href="#">3</a>
          <a href="#">4</a>
          <a href="#">5</a>
          <a href="#">6</a>
          <a class="active" href="#">&raquo;</a>
      </div>
      </div> -->
      