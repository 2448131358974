
  <mat-card>       
<div>
<span>Added Drugs</span>
</div>
<div class="Addeddrug" > 
<table>
<td>
<div>
<span *ngFor="let med of dogModel.medicines , let i = index" style="">  <p >{{med?.name}}</p>    
      <p>{{med?.description}}</p>
          <i class="material-icons" (click)="delete(i)" >delete</i>
          </span>
</div>
<div>
<span style="text-align: left;">   </span>
</div>
</td>
<td style="center"></td>
</table>
</div>

<div style="margin-top: 30px">
<span>drug</span>
<div>
<div>
<table >
<tr>
<td>
<section class="example-section">

        <span class="checkBoxClass">
        <mat-checkbox class="example-margin" style="margin-right:30px" id="Tablet"  checked>Tablet</mat-checkbox>
        </span>
        <span class="checkBoxClass">
         <mat-checkbox class="example-margin"  style="margin-right:30px" id="Syrup" >Syrup</mat-checkbox>
        </span>
        <span class="checkBoxClass">
         <mat-checkbox class="example-margin" style="margin-right:30px" id="Powder">Powder</mat-checkbox>
        </span>
 


      


 
</section>
</td>

<td>
  <form class="example-form" >
<div style = "position : relative">
  <input class="showdrug" matInput placeholder="{{popupType}} Name"  style="margin-left: 150px;width: 330px;" [(ngModel)]="name" name="name"
        (ngModelChange)="checkSuggestion()" [ngClass]="popupError ? 'errorMed' : ''" value=" " name=" ">
<div  [ngClass] = "suggestions && suggestions.length && checkVar ? 'suggestionBox' : 'hideDrop'">
        <p *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion.name , $event)">
          {{suggestion?.name}}
        </p>
      </div>
      </div>
  </form>
 </td>

 </tr>
 </table>
</div>
<table style="margin-top: 30px">
<tr>
<td >
<div style="margin-right: 50px;">
<span>Dosage</span>
<section>
 
    <button mat-raised-button color="accent" style=";font-size:12px;" class="btn btn-outline-dark1 " (click)="plusTab()">+</button>
    <span style="width: 100px;" class="btn btn-outline-dark1 "> {{tab}} {{popupType == 'Tablet' ? 'Tab' : ''}} {{popupType == 'Syrup' ? 'ml' : ''}}
            {{popupType == 'Powder' ? 'mg' : ''}}</span>
    <button mat-raised-button color="accent" style=";font-size:12px;"  class="btn btn-outline-dark1 "(click)="minusTab()" >-</button>
   
</section>
</div>
</td>

<td>
<div style="margin-right: 50px;">
<span>Duration</span>
<span style="color:pink;margin-left:10px">Week</span>
<section>
 
    <button mat-raised-button color="accent" style=";font-size:12px;" class="btn btn-outline-dark1 " (click)="plusWeek()">+</button>
    <span  style="width: 100px;" class="btn btn-outline-dark1 ">{{week}}</span>
    <button mat-raised-button color="accent" style=";font-size:12px;"  class="btn btn-outline-dark1 " (click)="minusWeek()">-</button>
   
</section>
</div>
</td>

<td>
<div>
<span>Rpeat</span>

 <div style="padding:10px">
    <span class="repeatdrug "   [ngClass]="repeat == 'Everyday' ? 'active' : ''"
        (click)="repeat = 'Everyday' " >Everyday </span>
    <span class="repeatdrug "  [ngClass]="repeat == 'Alternative days' ? 'active' : ''"
        (click)="repeat = 'Alternative days'" >Alternative Days</span>
    <span class="repeatdrug "  [ngClass]="repeat == 'Specific Days' ? 'active' : ''"
        (click)="repeat = 'Specific Days'  ">Specific Days</span>
   </div>


</div>   
</td>

</tr>
</table >
<table style="margin-top: 30px">
<tr>
<td>
<span>
Time of the day 
</span>
<section>
 
    <span style=";font-size:12px;" class="background" *ngFor="let ti of timeSlots" class="background" [ngClass]="ti.status == true ? 'actives' : ''"
        (click)="updateTimeSlot(ti)">{{ti.title}}  </span>
    
</section>
</td>

<td >
<div style="margin-left: 190px;">
<span>
To be taken
</span>
<section>
  <span class="repeatdrug "   [ngClass]="taken == 'Before Fodd' ? 'active' : ''" (click)="taken = 'Before Fodd' " >Before Fodd </span>
    <span  class="repeatdrug " [ngClass]="taken == 'After food' ? 'active' : ''" (click)="taken= 'After food' ">After food </span>
   
</section>
</div>
</td>
</tr>
</table>

<div>
<section style="margin-top: 30px">
 
    <button mat-raised-button color="accent" style="font-size:12px;" class="btn btn-dark " (click)="addDrug()" >Add drug</button>
    <button mat-raised-button color="accent" style="font-size:12px;" class="btn btn-outline-dark " (click)="NeedClearity()"  >Need Clarity</button>
    <button mat-raised-button color="accent" style="margin-left:300px;;font-size:12px;" class="btn btn-outline-darks " (click)="Save()">Save</button>
    <button mat-raised-button color="accent" style="font-size:12px;" class="btn btn-darks "(click)="Translate()" >Complete Transactions</button>

</section>

</div>
 


