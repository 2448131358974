
<div  class="mat-card card">

    <div class="row">
      <div class="col-8">
          <h2 class="main-heading">
            Manage Order
           
          </h2> 
      </div>  
      <div class="col-4">
        <!-- <button mat-button class="submit" (click)="addUser()">Add Order</button> -->
      </div>
    </div> 
    <div class="matTableDiv">
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
  
  
     
  
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Name </th>
        <td mat-cell *matCellDef="let element" id="name">
          <ng-container *ngIf="element.product.name == undefined || element.product.name == '';else name">NA</ng-container>
                      <ng-template #name>{{element.product.name}}</ng-template>
   </td>
      </ng-container>

     
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Id </th>
        <td mat-cell *matCellDef="let element" id="id">
          <ng-container *ngIf="element.product._id == undefined || element.product._id == '';else _id">NA</ng-container>
                      <ng-template #_id>{{element._id}}</ng-template>
   </td>
      </ng-container>


      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Price </th>
        <td mat-cell *matCellDef="let element" id="price">
          <ng-container *ngIf="element.product.price == undefined || element.product.price == '';else price">NA</ng-container>
                      <ng-template #price>{{element.product.price}}</ng-template>
   </td>
      </ng-container>
  


      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Location</th>
        <td mat-cell *matCellDef="let element" id="address">
          <ng-container *ngIf="element.address.location == undefined || element.address.location == '';else address">NA</ng-container>
          <ng-template #address>{{element.address.flat_no}}{{element.address.location}}</ng-template>
   </td>
      </ng-container>


      <ng-container matColumnDef="assignPartner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Assign Delivery Guy </th>
        <td mat-cell *matCellDef="let element" id="assignPartner"  >


          <!-- <mat-label> <span> Select Delivery Guy </span></mat-label> -->
      

          <mat-select name="deliveryGuy"  class="statusSelect" [(ngModel)] = "element.deliveryId">
            <mat-option   *ngFor="let a of deliveryGuy" [value]="a._id" (click)="addSurcharge(a._id,element._id,a.name,a.mobile,a.images)">{{a.name}}</mat-option>
          
          </mat-select>
    
   </td>
      </ng-container>
    

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
        <td mat-cell *matCellDef="let element" id="status">
          <ng-container *ngIf="element.status == undefined || element.status == '';else  status">NA</ng-container>
          <ng-template #status>{{element.status}}</ng-template>
   </td>
      </ng-container>
    


      <!-- <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Location</th>
        <td mat-cell *matCellDef="let element" id="address">
          <ng-container *ngIf="element.address.location == undefined || element.address.location == '';else address">NA</ng-container>
          <ng-template #address>{{element.address.flat_no}}{{element.address.location}}</ng-template>
   </td>
      </ng-container> -->
  
  
  
      <ng-container  matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef > Action </th>
       <td mat-cell *matCellDef="let element">
         <!-- <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span> -->
           <span class="material-icons editDelete" (click)="deleteUser(element._id)" title="Delete">delete</span>
   </td>
     </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns">
  
      </tr>
      <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">
  
      </tr>
  
    </table>
    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = $event">
  </mat-paginator>
   </div>
  </div>
  