<router-outlet>
</router-outlet>

<div *ngIf="uploadService.loadingFlag" class="loadingClass">
    <div>
      <p class="title">Please Wait</p>
      <p class="percentage"> {{uploadService.uploadPercentage}}% Uploaded...</p>
    </div>
  </div>

  <div *ngIf="loader.loading" class="loaderClassforloader">
    <div class="row">
        <div class="col-md-12">
          <div class="lottieWrapper">
            <ng-lottie
            [options]="lottieConfig" 
            class="lottieClass"
            *ngIf="loadingFlag"
            (animationCreated)="animationCreated($event)"
            ></ng-lottie>
          </div>
  
        </div>
    </div>
  </div>
  