import { Directive, HostListener, HostBinding, Input } from '@angular/core';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Directive({
  selector: '[search]'
})
export class SearchDirective {
  @Input() options:string;
  constructor(
    private loader:LoaderService
  ) {
    setTimeout(() => {
      console.log("hello",this.options);
    }, 1000);
   }
   @HostBinding('class')
   elementClass = 'matIconClassForSearch';

   @HostListener('click', ['$event.target'])
   public onClick(event: Event) {
    this.loader.searchFlag = true;
    console.log("asldfjlks");
   }

}
