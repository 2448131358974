




<div  class="mat-card card">


  <!-- <div  class="search" *ngIf="searchHelperFlag" [ngClass]="searchFlag == true ? 'searchNew':'searchOld'">
    <button class="button"  >
      <mat-icon class="menu-button" (click)="hideSearch()">close</mat-icon>
      </button>
      <div class="form-wrapper">
        <form class="form ng-untouched ng-pristine ng-valid" (submit)="searchComplete()">
          <div class="form-content">
            <input autocomplete="off" tabindex="-1" [(ngModel)]="searchVariable" class="search-input" [ngModelOptions]="{standalone: true}" placeholder="Search...">
          </div>
          <span class="info">Hit enter to search</span>
        </form>
      </div>
    </div> -->


  <div class="row">
    <div class="col-8">
        <h2 class="main-heading">
          Manage Breeds
          <!-- <span class="matIconClassForSearch">
            <mat-icon (click)="showSearch()">search</mat-icon>
            <mat-icon (click)="showFilter()"> filter_list </mat-icon>
            <mat-icon *ngIf="refreshFlag" (click)="refreshData()">refresh</mat-icon>
            <div *ngIf="filterFlagHelper" class="filterClass">
            <mat-icon class="closeClass" (click)="hideFilter()"> close </mat-icon>
              <div>
                <p class="pClass"> 
                  <mat-label> <span> Status </span> </mat-label>
                  <mat-select class="statusSelect" [(ngModel)]="filter.status" >
                    <mat-option value="none" >None</mat-option>
                    <mat-option value="true">Active</mat-option>
                    <mat-option value="false">Deactivate</mat-option>
                  </mat-select>
                </p>
               
                <p class="pClass">
                  <mat-label> <span> Role </span></mat-label>
                  <mat-select class="statusSelect" [(ngModel)]="filter.role">
                    <mat-option value="none">None</mat-option>
                    <mat-option value="Support Manager"> Support Manager </mat-option>
                    <mat-option value="Support Staff"> Support Staff </mat-option>
                  </mat-select>
                </p>
                <p class="pClass">
                  Created on
                  <span > 
                    <input class="dateInput" matInput [matDatepicker]="picker" [(ngModel)]="filter.startDate" (focus)="picker.open()" (click)="picker.open()" placeholder="Start Date">
                    <mat-datepicker #picker></mat-datepicker>
                    <input class="dateInput" matInput [matDatepicker]="picker1" [(ngModel)]="filter.endDate" (focus)="picker1.open()" (click)="picker1.open()" placeholder="End Date">
                    <mat-datepicker #picker1></mat-datepicker>
                  </span> 
                </p>
              </div>
              <div class="buttons">
                <button (click)="resetData()"> Reset </button>              
                <button (click)="filterData()"> Submit </button>
              </div>
            </div>
          </span> -->
        </h2> 
    </div>  
    <div class="col-4">
      <button mat-button class="submit" (click)="addUser()">Add Breed</button>
    </div>
  </div> 
  <div class="matTableDiv">
<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">


    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" id="name">
        <ng-container *ngIf="element.name == undefined || element.name == '';else Name">NA</ng-container>
                    <ng-template #Name>{{element.name}}</ng-template>
 </td>
    </ng-container>


    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
      <td mat-cell *matCellDef="let element" id="mobile">
        <ng-container *ngIf="element.mobile == undefined || element.mobile == '';else Nat">NA</ng-container>
                    <ng-template #Nat>{{element.mobile}}</ng-template>
 </td>
    </ng-container>


 








  <ng-container  matColumnDef="action">
     <th mat-header-cell *matHeaderCellDef > Action </th>
    <td mat-cell *matCellDef="let element">
      <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span>
        <span class="material-icons editDelete" (click)="deleteUser(element._id)" title="Delete">delete</span>
</td>
  </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns">

    </tr>
    <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">

    </tr>

  </table>
  <mat-paginator [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageEvent = $event">
</mat-paginator>
 </div>
</div>
  <!-- <div class="footer">
    <div class="pagination">
        <a class="active" href="#">&laquo;</a>
        <a class="active" href="#">1</a>
        <a href="#">2</a>
        <a href="#">3</a>
        <a href="#">4</a>
        <a href="#">5</a>
        <a href="#">6</a>
        <a class="active" href="#">&raquo;</a>
    </div>
    </div> -->
    