import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddInventoryComponent } from './admin/inventory/add-inventory/add-inventory.component';
import { ListInventoryComponent } from './admin/inventory/list-inventory/list-inventory.component';
import { AuthguardService } from './services/Authguard/authguard.service';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { TermsComponent } from './shared/terms/terms.component';
import { VendorServicesService } from './services/vendorServices/vendor-services.service';
const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
  },
  {
    path: 'vendor',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
  },
  {
    path: 'privacy',
    component:PrivacyComponent
  },
  {
    path: 'terms',
    component:TermsComponent
  },
  // {
  //   path: 'inventory/add',
  //   component:AddInventoryComponent
  // },
  // {
  //   path: 'inventory/list',
  //   component:ListInventoryComponent
  // },
  {
    path: '',
    redirectTo: '/admin',
    pathMatch : 'full'
  },
  {
    path: '**',
    redirectTo: '/admin',
     pathMatch : 'full'
  },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
