<section class="smart-features" id="privacypolicy" style="">
    <div class="container">
        <div class="row">
            <div class="col-md-12 ppcontent" style="margin-top: 100px;">
                <p style="margin-top:20px;margin-right:0in;margin-bottom:23.25pt;margin-left:0in;line-height:normal;font-size:15px;background:white;text-align: center;"><span style="font-size:36px;color:#2a1d30;text-align: center;font-weight: 700;">Privacy Policy</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;color:#31363A;">Last Revised December16, 2019.&nbsp;</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We want you to understand how and why dogs&joys.com, the website and online service of dogs&joys.com (“dogs&joys,” “we,” or “us”)collects, uses, and shares information about you when you use our websites, mobile apps, widgets, and other online products and services (collectively, the "Services") or when you otherwise interact with us or receive a communication from us. This Privacy Policy applies to all of our Services.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><strong><span style="font-size:20px;color:#31363A;">What We Collect</span></strong></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Information and Content You Provide&nbsp;</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We collect information you provide to us directly when you use the Services. This includes:</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Account information.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;To create an account, you must provide your name, mobile number and a password. You may also provide an email address. We also store your user account preferences and settings.</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Content you submit.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;We collect the content you submit to the Services. This includes your posts, messages, and instructions, including saved drafts, and media files uploaded by you.</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Actions you take.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;We collect information about the actions you take when using the Services. This includes your current location.</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Transactional information.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;If you purchase products or services from us (e.g. subscriptions), we will collect certain information from you, including information about the product or service you are purchasing. Payments are processed by third-party payment processors (e.g., Stripe and PayPal), so please refer to the applicable processor’s terms and privacy policy for more information about how payment information is processed and stored.</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Other information.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;You may choose to provide other information directly to us. For example, we may collect information when you fill out a form, participate in our activities or promotions, apply for a job, request customer support or otherwise communicate with us.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Information We Collect Automatically</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">When you access or use our Services, we may also automatically collect information about you. This includes:</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Log and usage data.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;We may log information when you access and use the Services. This may include your IP address, user-agent string, browser type, operating system, referral URLs, device information (e.g., device IDs), pages visited, links clicked, the requested URL, hardware settings, and search terms. </span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Information collected from cookies and similar technologies.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;We may receive information from cookies, which are pieces of data your browser stores and sends back to us when making requests, and similar technologies. We use this information to improve your experience, understand user activity, personalize content and advertisements, and improve the quality of our Services. For more information on how you can disable cookies, please see “Your Choices” below.</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Location information.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;We may receive and process information about your location. For example, with your consent, we may collect information about the specific location of your mobile device (e.g., by using Critical or S.O.S Alerts). We may also receive location information from you when you choose to share such information on our Services, including by associating your content with a location, or we may derive your approximate location from other information about you, including your IP address.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Information Collected from Other Sources</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We may receive information about you from other sources, including from other users and third parties, and combine that information with the other information we have about you, e.g. when another user leaves a tribute on your Memorial.</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Linked services.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;If you authorize or link other services (e.g., third-party apps or websites) to access your dogs&joys account, dogs&joys receives information about your use of that service when it uses that authorization. Linking services may also cause the other service to send us information about your account with that service. To learn how information is shared with linked services, see “How Information About You Is Shared” below.</span></p>
<p style="line-height:normal;font-size:15px;background:white;padding-left: 15px;padding-right: 15px;"><em><span style="font-size:14px;;color:#6f6f6f;font-weight: 600;">Information collected from integrations.</span></em><span style="font-size:14px;;color:#6f6f6f;">&nbsp;We also may receive information about you, including log and usage data and cookie information, from third-party websites that integrate our Services, including our embeds, buttons, and advertising technology.&nbsp;</span></p>

<p style="line-height:normal;font-size:15px;background:white;"><strong><span style="font-size:20px;;color:#3f3f3f;">How We Use Information About You</span></strong></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We use information about you to:</span></p>
<ul style="margin-bottom:20px;" type="disc">
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Provide, maintain, and improve the Services;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Research and develop new services;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Send you technical notices, updates, security alerts, invoices and other support and administrative messages;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Provide customer service;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Communicate with you about products, services, offers, promotions, and events, and provide other news and information we think will be of interest to you;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Promote safety, integrity and security of the delivery of the Services;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Monitor and analyze trends, usage, and activities in connection with our Services; and</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">Personalize the Services and provide advertisements, content and features that match user profiles or interests.&nbsp;</span></li>
</ul>
<p style="line-height:normal;font-size:15px;background:white;"><strong><span style="font-size:20px;;color:#3f3f3f;">How Information About You Is Shared</span></strong></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">When you use the Services, certain information may be shared with other users and the public. For example:</span></p>
<ul style="margin-bottom:20px;" type="disc">
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">When you permit your Memorial, including posts, media files, and other information to be viewed by other users and/or the public.</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">When you permit your private messages to be sent to other users and/or specified contacts.</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">When you use features of the Services that shares your location information with specified contacts and/or via your selected social media platform.</span></li>
</ul>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">Otherwise, we do not share, sell, or give away your personal information to third parties unless one of the following circumstances applies:</span></p>
<ul style="margin-bottom:20px;" type="disc">
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">With linked services.</span></em><span style="font-size:14px;">&nbsp;If you link your dogs&joys account with a third-party service, dogs&joyswill share the information you authorize with that third-party service.&nbsp;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">With our partners.</span></em><span style="font-size:14px;">&nbsp;We may share information with vendors, consultants, and other service providers (but not with advertisers and ad partners) who need access to such information to carry out work for us. The partner’s use of personal data will be subject to appropriate confidentiality and security measures.</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">To comply with the law.</span></em><span style="font-size:14px;">&nbsp;We may share information in response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, legal process or governmental request, including, but not limited to, meeting national security or law enforcement requirements. To the extent the law allows it, we will attempt to provide you with prior notice before disclosing your information in response to such a request.&nbsp;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">In an emergency.</span></em><span style="font-size:14px;">&nbsp;We may share information if we believe it's necessary to prevent imminent and serious bodily harm to a person.</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">To enforce our policies and rights.</span></em><span style="font-size:14px;">&nbsp;We may share information if we believe your actions are inconsistent with our Terms of Use, or to protect the rights, property, and safety of ourselves and others.</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">With our affiliates.</span></em><span style="font-size:14px;">&nbsp;We may share information between and among dogs&joys, and any of our parents, affiliates, subsidiaries, and other companies under common control and ownership.</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">With your consent.</span></em><span style="font-size:14px;">&nbsp;We may share information about you with your consent or at your direction.</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><em><span style="font-size:14px;">Aggregated or de-identified information.</span></em><span style="font-size:14px;">&nbsp;We may share information about you that has been aggregated or anonymized such that it cannot reasonably be used to identify you.&nbsp;</span></li>
</ul>
<p style="line-height:normal;font-size:15px;background:white;"><strong><span style="font-size:20px;;color:#3f3f3f;">Other Information</span></strong></p>

<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Information Security</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We take measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction. <span style="">For example, we use HTTPS while information is being transmitted.</span> We also enforce technical and administrative access controls to limit which of our employees have access to non-public personal information.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Data Retention</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">International Data Transfers</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We are based Singapore and we process and store information on servers located <span style="">in the Singapore Region of AWS.</span>. We may store information on servers and equipment in other countries depending on a variety of factors, including the locations of our users and service providers. By accessing or using the Services or otherwise providing information to us, you consent to the processing, transfer and storage of information in and to Singapore and other countries, where you may not have the same rights as you do under local law.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Additional Information for EEA Users</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">Users in the European Economic Area have the right to request access to, rectification of, or erasure of their personal data; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to withdraw consent for processing where they have previously provided consent. These rights can be exercised using the contact information provided at the end of this document. EEA users also have the right to lodge a complaint with their local supervisory authority.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">As required by applicable law, we collect and process information about individuals in the EEA only where we have legal bases for doing so. Our legal bases depend on the Services you use and how you use them. We process your information on the following legal bases:</span></p>
<ul style="margin-bottom:20px;" type="disc">
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">You have consented for us to do so for a specific purpose;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">We need to process the information to provide you the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">It satisfies a legitimate interest (which is not overridden by your data protection interests), such as preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our legal rights and interests, research and development, and marketing and promoting the Services;</span></li>
<li style="line-height:normal;font-size:15px;color:#31363A;background:white;"><span style="font-size:14px;">We need to process your information to comply with our legal obligations.</span></li>
</ul>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Children</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">Children under the legal age (legal capacity) to enter into a binding contract are not allowed to create an account or otherwise use the Services. Additionally, if you are in the EEA, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or we need to have obtained verifiable consent from your parent or legal guardian.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:18px;;color:#31363A;">Changes to This Policy</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">We may change this Privacy Policy from time to time. If we do, we will let you know by revising the date at the top of the policy. If we make a change to this policy that, in our sole discretion, is material, we will provide you with additional notice (such sending you a notification). We encourage you to review the Privacy Policy whenever you access or use our Services or otherwise interact with us to stay informed about our information practices and the ways you can help protect your privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.</span></p>
<p style="line-height:normal;font-size:15px;background:white;"><strong><span style="font-size:20px;;color:#3f3f3f;">Contact Us</span></strong></p>
<p style="line-height:normal;font-size:15px;background:white;"><span style="font-size:14px;;color:#6f6f6f;">If you have any questions about this Privacy Policy, please contact us at&nbsp;</span>
<a href="mailto:support@dogs&joys.com"><span style="font-size:14px;color: #976fa8;">support@dogs&joys.com</span></a><span style="font-size:14px;;color:#6f6f6f;">.</span></p>
<p style="line-height:107%;font-size:15px;">&nbsp;</p>
            </div>
        </div>
    </div>
</section>