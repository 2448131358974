<div class="container">
  <div class="row d-flex justify-content-center" >
    <div class="col-12">
        <mat-card>
            <form #f="ngForm">
        <div class="row ">
                <div class="col-12 ">
                    <!-- <h2 class="heading" >Add Food</h2>   -->
                    <h2 class="heading" *ngIf = "!id">Add Food</h2>  
                    <h2 class="heading" *ngIf = "id">Edit Food</h2>  
                

                </div>  
                <div class="inputarea">



                 
              














                  <div class="row">
                    <div class="col-4">
                      <!-- <label for="website">Website:</label>   -->
                      <select name="brand" [(ngModel)]="addForm.brand"  value="" #brand="ngModel"  class="form-control"> 
                        <option value="" selected hidden>Select brand</option> 
                        <option *ngFor = "let a of userId.brands" value="{{a?.brandName}}">{{a?.brandName}}</option>
                        <!-- <option >Select brand</option>  
                          <option value="The Dogfather">The Dogfather</option>  
                          <option value="K9 Agro Foods">K9 Agro Foods</option> 
                          <option value="BLACK DOG COMPANY">BLACK DOG COMPANY</option> 
                          <option value="Freshwoof">Freshwoof</option>  -->
                      </select> 
                  
    
                    </div>
                    <div class="col-4">
                      <input class="inputbox" name="name" [(ngModel)]="addForm.name"  value="" #name="ngModel" placeholder="Product Name" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required >
                  
                      <div
  [hidden]="name.valid || name.pristine"
  class="erro1"
  >Product Name is Required
  </div>
    
                    </div>

                    <div class="col-4">
                      <input class="inputbox" name="subTitle" [(ngModel)]="addForm.subTitle"  value="" #subTitle="ngModel" placeholder="Sub Title" [ngClass]="{ 'is-invalid': f.submitted && subTitle.invalid }" required >
                  
                      <div
  [hidden]="subTitle.valid || subTitle.pristine"
  class="erro1"
  >Sub Title is Required
  </div>
    
                    </div>
                 
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <!-- <input  > -->
                      <textarea matInput class="inputbox" type="textarea" placeholder=" Product Description" name="details" [(ngModel)]="addForm.details" #details="ngModel" [ngClass]="{ 'is-invalid': f.submitted && details.invalid }" required ></textarea>
                      <div
                      [hidden]="details.valid || details.pristine"
                      class="erro1"
                    >
                      
                    details is required
                      </div>
              </div> 
                  </div>


                  <div class="row">
                    <div class="col-4">
                      <input  type="number" class="inputbox" name="price" [(ngModel)]="addForm.price"  value="" #price="ngModel" placeholder=" Product Price" [ngClass]="{ 'is-invalid': f.submitted && price.invalid }" required >
                  
                      <div
  [hidden]="price.valid || price.pristine"
  class="erro1"
  >price is Required
  </div>
    
                    </div>

                    <div class="col-4">
                      <input  class="inputbox" name="capacity" [(ngModel)]="addForm.capacity"  value="" #capacity="ngModel" placeholder=" Product Capacity" [ngClass]="{ 'is-invalid': f.submitted && capacity.invalid }" required >
                  
                      <div
  [hidden]="capacity.valid || capacity.pristine"
  class="erro1"
  > Project Capacity is Required
  </div>
    
                    </div>

                    <div class="col-4">
                      <input  type="number" class="inputbox" name="ProductId" [(ngModel)]="addForm.ProductId"  value="" #ProductId="ngModel" placeholder=" Product ID" [ngClass]="{ 'is-invalid': f.submitted && ProductId.invalid }"  >
                  
                      <div
  [hidden]="ProductId.valid || ProductId.pristine"
  class="erro1"
  >Product Id is Required
  </div>
    
                    </div>



                    
                  </div>
              

                  
                  <div class="row">
                  
                    <div class="col-4">
                    
                      <div   class="browse">
                        Browse Image
                        <input type="file" class="opacity" (change)="detectFiles($event)" multiple/>
                      </div>
                      <div class = "imageDiv" style="display: flex;">
                        <div   class = "imageCover"  *ngFor = "let image of addForm?.images; let i = index" style="    position: relative;">
                      <img src="{{image.url}}" *ngIf="addForm?.images && addForm?.images.length > 0">
                      <div class="minus" (click)="deleteImage(image, i )" >-</div>
                    </div>
                      </div>
                      
    
                    </div>
                  </div>
              
        

             
          
                <div class="col-12">
            
        
                <div class="col-12">
              </div>
         
                    <button mat-button [disabled]="f.invalid" class="submit" (click)="AddSubAdmin()">ADD TO Food</button>
                   


                    

                </div>    
                
            </div> 
                  
            </div> </form>
        </mat-card>
      </div>    
</div>

</div>   
