
            
          <mat-card>
              <form #Form="ngForm">
            <div class="row ">
                <div class="co-12 heading">
                     Reset Password    
                 </div>    
                 <div class="col-12" class="field">
                    <input class="inputbox" type="password" name="password" [(ngModel)]="addForm.password" placeholder="Password" minlength="4" #password="ngModel" required>
                    <span *ngIf="password.invalid" class="material-icons">
                        {{icon}}
                        </span>
                        <span *ngIf="password.valid" class="material-icons">
                            {{icon1}}
                            </span>
                </div>
                <div class="col-12" class="field">
                    <input class="inputbox"  type="password"  type="{{type}}" placeholder="Confirm Password" name="confirmPassword" [(ngModel)]="addForm.confirm" #confirmPassword="ngModel" required>
                    <span class="material-icons" (click)="changeVisibility()">
                        {{Visibility}}
                        </span>
                </div>
                
                 <div class="col-12">
                 <button mat-stroked-button [disabled]="Form.invalid || addForm.password!=addForm.confirm" class="button1" (click)="login()">
                    <div *ngIf="!password.dirty || !addForm.password">Enter Password</div>
                    <div *ngIf="addForm.password && addForm.password.length < 4">Enter min 4 digit Password</div>

                    <div *ngIf="!confirmPassword.dirty && password.dirty && password.valid">Enter ConfirmPassword</div>
                    <div *ngIf="password.valid && confirmPassword.dirty">
                    <div *ngIf="addForm.password!=addForm.confirm">
                        Both Password Must be Equal
                    </div>
                    <div *ngIf="password.dirty && confirmPassword.dirty && addForm.password==addForm.confirm">Submit</div>
                </div>
                <div></div>

                 </button>
                 </div>
                 <!-- <div class="col-12">
                     <p class="accunt">Already have and account ?<span (click)="login()"> Login</span></p>
                 </div>     -->
             </div></form>
           </mat-card>
       
     