<div class="matTableDiv">
    <div>
        <input matInput (keyup)="applyFilterGrid($event.target.value)" placeholder="Filter">
      </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 width-100" matSort>


        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element" id="Name">{{element?.name || ''}}
                <!-- <ng-container *ngIf="element.name == undefined || element.name == '';else Name">NA</ng-container>
                <ng-template #Name>{{element.name}}</ng-template> -->
                <!-- <ng-container>{{element?.name || ''}}</ng-container> -->
            </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Joining Date </th>
            <td mat-cell *matCellDef="let element" id="createdAt">
              {{ element?.createdAt ? (element?.createdAt | date:'dd-MM-yy') : '' }}
                <!-- <ng-container *ngIf="element.createdAt == undefined || element.createdAt== '';else Name">NA</ng-container>
                <ng-template #Name>{{ element.createdAt | date:'dd-MM-yy' }}</ng-template> -->
            </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element" id="email">
              {{element?.email}}
          </td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns">

        </tr>
        <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">

        </tr>

    </table>
    <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
    </mat-paginator>
</div>