import { usersService } from '../../../services/tikaUser/user.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
accessToken:any;
addForm:any;
Visibility:any='visibility_off'
type:string='password';
icon:string="check_circle_outline";
confirmPassword:any=''
icon1="check_circle"
  constructor(
    private r:Router,private activeRoute:ActivatedRoute,
private UserService:usersService
  ) { 
    this.addForm={
      "password":'',
      "confirm":''
    }
 this.accessToken = this.activeRoute.snapshot.paramMap.get("id");

  }

  ngOnInit(): void {
   
  }
  changeVisibility()
   {
      if(this.Visibility=="visibility_off")
      {
        this.Visibility="visibility"
        this.type='text'
      }
      else if(this.Visibility=="visibility")
      {
        this.Visibility='visibility_off'
        this.type='password';
      }
   }
  login(){
    const data={
      "accessToken":this.accessToken, 
    "newPass":this.addForm.confirm}
    this.UserService.updateForgottenPassword(data , environment.api.admin).then((res)=>
    {
      console.log(res)
      console.log(this.addForm)
      
    this.r.navigate(['/admin/login']);
    })
    // this.r.navigate(['/admin/login']);
  }

}
