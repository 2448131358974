




<div  class="mat-card card">


    
  
  
    <div class="row">
      <div class="col-8">
          <h2 class="main-heading">
            Manage Vendor
           
          </h2> 
      </div>  
      <div class="col-4">
        <button mat-button class="submit" (click)="addUser()">Add Vendor</button>
      </div>
    </div> 
    <div class="matTableDiv">
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
  

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element" id="name">
          <ng-container *ngIf="element.name == undefined || element.name == '';else Name">NA</ng-container>
                      <ng-template #Name>{{element.name}}</ng-template>
   </td>
      </ng-container>
  
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element" id="email">
          <ng-container *ngIf="element.email == undefined || element.email == '';else email">NA</ng-container>
                      <ng-template #email>{{element.email}}</ng-template>
   </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
        <td mat-cell *matCellDef="let element" id="mobile">
          <ng-container *ngIf="element.mobile == undefined || element.mobile == '';else mobile">NA</ng-container>
                      <ng-template #mobile>{{element.mobile}}</ng-template>
   </td>
      </ng-container>

     



      
   

      



      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td  mat-cell *matCellDef="let element"  id="name" 
        >
        <span [ngClass]="element.approval == 'Approved' ? 'StatusGreen' :'StatusRed' ">
          {{ element.approval  }}
        </span>
        </td> 
      </ng-container>


    <ng-container  matColumnDef="action">
       <th mat-header-cell *matHeaderCellDef > Action </th>
      <td mat-cell *matCellDef="let element">
        <span class="material-icons editDelete" (click)="edit(element._id)" title="Edit">edit</span>
          <span class="material-icons editDelete" (click)="deleteUser(element._id)" title="Delete">delete</span>
  </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns">
  
      </tr>
      <tr class="data" mat-row *matRowDef="let row; columns: displayedColumns;">
  
      </tr>
  
    </table>
    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = $event">
  </mat-paginator>
   </div>
  </div>
