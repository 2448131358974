import { Countries } from './countries';

export class AppSettings {
  // public static API_ENDPOINT = "http://localhost:3000/api/";

  // public static API_ENDPOINT = 'http://192.168.0.143:3000/api/';

  // public static API_ENDPOINT = 'http://localhost:4210/';

  //  public static API_ENDPOINT = "https://dogsjoys.herokuapp.com/";
  //  public static API_ENDPOINT = "https://dogsandjoys-temp.herokuapp.com/";

  public static API_ENDPOINT = 'https://dhor.clinic.api.stageprojects.xyz/';

  // public static API_ENDPOINT = 'http://localhost:4002/';
  //

  public static API_TIMEOUT = 3000; // 1 second Timeout

  public static LOGIN_ERROR =
    'Either username/password invalid. Please try again.';

  public static LOGOUT_SUCCESS = 'Successfully Logout.';

  public static SITE_NAME = 'Grocery';

  public static GENERIC_ERROR = 'There is some error. Please try again.';

  public static DEFAULT_COURSE = '5c4645c20a3168054e04f915';

  public static BUCKETPATH = {
    CATEGORY: 'CATEGORY',
    CHEF: 'CHEF',
    VOUCHER: 'VOUCHER',
    BANNERIMAGE: 'BANNERiMAGE',
    ETC: 'ETCImage',
  };

  public static ROLE = {
    ADMIN: 'admin',
    USER: 'user',
  };

  public static COUNTRIES_LIST = Countries.ALL_COUNTRIES;

  public static MESSAGE = {
    USER: {
      ADD_TITLE: 'Registerd Successfully',
      ADD: 'Congratulations you have created an account. A confirmation email has been sent. To activate the Grocery, simply click on the word Verify in the email you received. Be sure to check your Clutter and Junk Email folders.',
      EDIT: 'User Info has been updated',
      DELETE: 'User has been deleted',
      DELETE_CONFIRM: 'Are you sure you want to delete this user?',
      SIGNUP_ERR: 'Email address already exist. Please try again.',
      FORGET_PASSWORD:
        'Reset Password link has been sent at your email address.',
      RESET_PASSWORD:
        'Congratulations! Your password has been reset successfully.',
      CHANGE_PASSWORD:
        'Congratulations! Your password has been changed successfully.',
    },
    CATEGORY: {
      ADD: 'New Category has been added.',
      EDIT: 'Category Info has been updated.',
      DELETE: 'Category has been deleted.',
      DELETE_CONFIRM: 'Are you sure you want to delete this category?',
    },
    UNIT: {
      ADD: 'New Unit has been added.',
      EDIT: 'Unit Info has been updated.',
      DELETE: 'Unit has been deleted.',
      DELETE_CONFIRM: 'Are you sure you want to delete this Unit?',
    },
    CHEF: {
      ADD: 'New Chef has been added.',
      EDIT: 'Chef Info has been updated.',
      DELETE: 'Chef has been deleted.',
      DELETE_CONFIRM: 'Are you sure you want to delete this Chef?',
    },

    PRODUCT: {
      ADD: 'New Product has been added.',
      EDIT: 'Product Info has been updated.',
      DELETE: 'Product has been deleted.',
      DELETE_CONFIRM: 'Are you sure you want to delete this Product?',
    },
    ORDER: {
      ADD: 'New Order has been added.',
      EDIT: 'Order Info has been updated.',
      DELETE: 'Order has been deleted.',
      DELETE_CONFIRM: 'Are you sure you want to delete this Order?',
    },
    ATTRIBUTE: {
      ADD: 'New Attribute has been added.',
      EDIT: 'Attribute Info has been updated.',
      DELETE: 'Attribute has been deleted.',
      DELETE_CONFIRM: 'Are you sure you want to delete this Attribute?',
    },
    LOGIN: {
      VERFY_ACCESS_CODE_ERR: 'Access Code is invalid. Please try again.',
    },
  };

  public static FIELDS = {};

  public static CSV_NAME = {
    FRONTEND_USER_LIST: 'Grocery Customer Customers',
    SUBADMIN_LIST: 'Grocery Subadmin users',
    BANNERIMAGE: 'Banner Image',
  };

  public static URLS = {
    ADMIN: {
      DASHBOARD: '/admin/dashboard',
      USERS: '/admin/subadmin',
      FRONTEND: '/admin/customer',
      BANNER: '/admin/banner',
      DOCTORS: '/admin/doctors',
      PATIENT: '/admin/patient',
      SUBADMIN: '/admin/sub-admin',
    },
  };

  public static SITE_TITLE_PREFIX = AppSettings.SITE_NAME + ' - ';

  public static ADMIN_METADATA = {
    DASHBOARD: {
      title: 'Dashboard',
    },
    CHANGE_PASSWORD: {
      title: 'Change Password',
    },
    PROFILE: {
      title: 'Profile',
    },
    LOGIN: {
      title: 'Login',
    },
    FORGET_PASSWORD: {
      title: 'Forgot Password',
    },
    RESET_PASSWORD: {
      title: 'Reset Password',
    },
    USER_LIST: {
      title: 'Sub Admin',
    },
    ADD_USER: {
      title: 'Add Sub Admin',
    },
    EDIT_USER: {
      title: 'Edit Sub Admin',
    },

    FRONTEND_LIST: {
      title: 'Customer',
      urls: [
        { title: 'Dashoard', url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: 'Customer' },
      ],
    },
    ADD_FRONTEND: {
      title: 'Add Customer',
      urls: [
        { title: 'Dashoard', url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: 'Customer', url: AppSettings.URLS.ADMIN.FRONTEND },
        { title: 'Add Customer' },
      ],
    },
    EDIT_FRONTEND: {
      title: 'Edit Customer',
      urls: [
        { title: 'Dashoard', url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: 'Customer', url: AppSettings.URLS.ADMIN.FRONTEND },
        { title: 'Edit Customer' },
      ],
    },

    BANNER_LIST: {
      title: 'Banner',
      urls: [
        { title: 'Dashoard', url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: 'Banner' },
      ],
    },
    ADD_BANNER: {
      title: 'Add Banner',
      urls: [
        { title: 'Dashoard', url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: 'Banner', url: AppSettings.URLS.ADMIN.USERS },
        { title: 'Add User' },
      ],
    },
    EDIT_BANNER: {
      title: 'Edit Banner',
      urls: [
        { title: 'Dashoard', url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: 'Banner', url: AppSettings.URLS.ADMIN.USERS },
        { title: 'Edit Banner' },
      ],
    },
    CATEGORY_LIST: {
      title: 'Categories',
      urls: [{ title: 'Categories' }],
    },
    ADD_CATEGORY: {
      title: 'Add Category',
      urls: [{ title: 'Add Category' }],
    },
    EDIT_CATEGORY: {
      title: 'Edit Category',
      urls: [{ title: 'Edit Category' }],
    },
  };
}
